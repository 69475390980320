.modalSuccessTransaction {
	display: flex;
	height: auto !important;

	.buttons {
		display: flex;
		flex-direction: column;
		.btn-copy-message, .btn-copy-message-corresponsible {
			width: 290px;
		}
		.btn-new-transaction {
			width: 220px;
			align-self: center;
		}
		:last-child {
			margin-left: 16px;
		}
	}

	.text {
		font-size: 16px;
		margin-top: 28px;
		text-align: center;
		b {
			text-decoration: none;
		}
		:last-child {
			margin-bottom: 28px;
		}
	}

	.btn-copy-message {
		margin-left: 16px;
	}	
}