
// .gambiarra {
//     width: 100vw;
//     height: 6vh;
//     background: chocolate;
// }

.trial-popup {
    height: 15vh;
    z-index: 1 !important;
    display: flex;
    width: 100%;
    position: absolute;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 0 2px 12px $color-black-opacity;
    background: #175493;

    &-image {
        width: 150px;
        overflow: hidden;
        position: relative;
        left: 30px;

        img {
            position: relative;
            top: 7px;
            width: 400px;
            height: 80px;
        }
    }

    &-text {
        margin-left: 160px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        justify-items: center;
        width: 30%;
        font-size: 25px;
        color: white;

        b {
            text-decoration: none;
            color: $color-primary;
        }
    }

    &-data {
        margin-left: 250px;
        display: flex;
        
        div {
            text-align: center;
            max-width: 200px;
            flex-wrap: wrap;
            margin-left: 10px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
            justify-items: center;
            font-size: 17px;
            color: white;
            &:nth-child(1) {
                max-width: 100px;
                span {
                    background: white;
                    color: #175493;
                }
            }

            span {
                background: $color-primary;
                color: white;
                padding: 10px;
                border-radius: 200px;
            }
        }
    }

    &-close {
        margin-left: 30px;
        margin-top: 5px;
        color: white;
        font-size: 20px;
        font-weight: 700;
    }
}

.modalBoleto {
    .container-modal {
        width: 100%;
        height: 80vh;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        
        .pdf-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
        
            .title {
                flex: 1;
                color: #175493;
                text-align: center;
            }

            .body {
                flex: 8;
                width: 100%;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;

                embed {
                    width: 80%;
                    height: 100%;
                }
            }

            .footer {
                flex: 1;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;

                span {
                    width: 200px;
                    height: 40px;
                    background: #175493;
                    color: $color-white;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    border-radius: 20px;
                    box-shadow: 0 5px 3px $color-black-opacity;
                    cursor: pointer;
                    transition: all .2s ease;
                    
                    &:hover {
                        box-shadow: 0 3px 1px $color-black-opacity;
                        background: darken(#175493, 5%);
                    }
                }
            }
        }
    }

    .carregando {
        .message {
            font-size: 40px;
            letter-spacing: 2px;
            word-spacing: 3px;
            text-align: center;
            color: #175493;
            position: relative;
            top: -80px;

            i {
                font-size: 50px;
            }
        }
        .load {
            .loader {
                width: 130px;
                height: 130px;
            }
        }
    }
}


.alert-notify {
    margin-bottom: 20px;
   .notify-title {
       display: flex;
       width: 100%;
       justify-content: center;
       align-content: center;
       align-items: center;
       justify-items: center;
       margin-top: 20px;
   }

   .line {
    margin: 10px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    span {
        border-bottom: 1px solid $color-primary;
        margin-left: 20px;
        height: 30px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-content: flex-end;
        align-items: flex-end;
        justify-items: flex-end;
        min-width: 180px;
    }
    b {
           text-decoration: none;
           font-weight: 400;
           color: $color-grey-light;
           font-size: 10px;
           position: absolute;
           top: 1px;
           left: 1px;
       }
   }
}