.btn {
    &--primary {
        background-image: linear-gradient(to right bottom, $color-primary, $color-secondary);
        color: $color-white;
    }

    &:focus {outline:none;}
    &::-moz-focus-inner {border:0;}

    margin-top: 20px;
    border: none;
    border-radius: 2px;
    padding: 12px 18px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    background-color: #2196f3;
    box-shadow: 0 0 4px #999;
    outline: none;
    background-position: center;
    transition: background 0.8s;
  }
  .btn:hover {
    background: $color-secondary radial-gradient(circle, transparent 1%, $color-secondary 1%) center/15000%;
  }
  .btn:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
    border: none;
  }
  
  

