.modalEditClient {
    min-width: 50vw;
    &__footer {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        
        span {
            margin-top: 10px;
            width: 180px;
            height: 30px;
            cursor: pointer;
            background: $color-secondary;
            color: $color-white;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            border-radius: 20px;
        }
    }
}