.header {
  background: $color-white;
  height: 8vh;
  font-size: 13px;
  display: flex;
  color: $color-grey-light;
  border-bottom: 1px solid $color-grey-light1;

  &__logo {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      margin-left: 10px;
      div {
          height: 40px;
          background-image: url('https://i.ibb.co/1G1R9dv/LOGO-FINAL2.png');
          background-size: cover;
          background-position: center;
          width: 120px;
      }
  }

  &__navigation {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex: 4;
          ul {
              list-style: none;
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;
              align-content: flex-end;
              width: 100%;

              li {
                  margin-right: 20px;
                  cursor: pointer;
                  display: flex;
                  
                  i {
                      font-size: 18px;
                  }
              }
          }
  }

  &__avatar {
      align-self: center;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      position: relative;
      border-left: 1px solid $color-grey-light1;
      height: 100%;
      cursor: pointer;
      z-index: 1;
      min-width: 188px;

      &:hover {
          background-color: $color-grey-light3;
      }
      
      &--sigla {
          margin-left: 10px;
          height: 30px;
          width: 30px;
          border-radius: 200px;
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          font-size: 10px;
          z-index: 1;

          img {
              border-radius: 200px;
              width: 30px;
              z-index: 1;
          }
      }
      
      &--name {
          letter-spacing: 4px;
          margin-right: 10px;
          margin-left: 10px;
          font-weight: 700;
      }
      
      &-checkbox {
          display: none;    
          &:checked ~ div {
              display: block;
          }
      }

      &-list {
          display: none;
          position: absolute;
          top: 101%;
          width: 180px;
          right: 8px;
          text-align: center;
          background: $color-white;
          border: 1px solid $color-grey-light1;
          font-size: 14px;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          ul {
              list-style: none;
              padding: 0px;
              li {
                  padding: 5px;
                  border-bottom: 1px solid $color-grey-light1;
                  width: 100%;
                  
                  i {
                      margin-right: 5px;
                  }
                  &:hover {
                      background-color: $color-grey-light3;
                  }
              }
          }
      }   
  }


      .count-notification {
          background: $color-grey-light;
          color: $color-white;
          font-weight: 700;
          font-size: 10px;
          border-radius: 100px;
          width: 15px;
          height: 15px;
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          margin-right: 5px;
      }
}

@media only screen and (max-width: 768px) {
  .header {
      &__logo {
          div {
              height: 40px;
              background-image: url('https://i.ibb.co/mJ7FRKN/SIMBOLO-GRAFICO.png');
              background-size: cover;
              background-position: center;
              width: 40px;
          }
      }

      &__navigation {
              ul {
                  li {
                      margin-right: 5px;
                  }
              }
      }
  }
}

.modalBoleto {
  width: 100vw;
}

.modal-change-data {
    display: flex;
    flex-direction: column;
    width: 30%;
    .modal-change-data-header {
        color: $color-secondary;
    }
    .modal-change-data-body {
        .modal-change-data-button {
            width: 50px;
            position: relative;
            left: 40%;
        }
    }
}