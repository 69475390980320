#bank-slip-document {
  .dropzone {
    cursor: pointer;
    outline: 0;
  }
  .plus-container {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 220px;
    height: 120px;
    border: 3px solid rgb(187, 187, 187);
    border-radius: 12px;
    margin: 0 auto;
    border-style: dotted;

    .plus {
      color: rgb(187, 187, 187);
      font-weight: 100;
      font-size: 50px;
    }
  }
  .document-list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0;
      }
    }
  }
}
