.home {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  h1.observation {
    margin-top: 0;
  }
  .observation {
    &.parcel {
      b {
        text-decoration: none;
      }
    }
  }
  &__container {
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: flex-start;
    // box-shadow: 0 5px 80px $color-black-opacity;
    flex-direction: column;
    
    &-body {
      z-index: 0;
    }
    &-header {
      margin-top: 10px;
      height: 10%;
      text-align: center;
    }
  }

  .adhesion-obs {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    > div {
      display: flex;
      justify-content: space-around;

      .column {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
        b {
          margin-top: 10px;
          text-decoration: none;
          color: $color-secondary;
          font-size: 20px;
        }
      }
    }
    h1 {
      font-size: 20px;
      color: $color-secondary;
    }
  }
}
