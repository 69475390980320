.modalChangeCard {
    width: 100vw;

    .card-input {
        display: flex;
        flex-direction: row;

        &-inner {
            width: 100%;
            display: flex;
            flex-direction: row;
        }
    }

    .alert {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 15px;
        font-weight: bold;
        color: $color-secondary;
    }

    .modal-footer {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin-top: 10px;

        span {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            width: 180px;
            height: 30px;
            font-size: 20px;
            background: $color-secondary;
            color: $color-white;
            cursor: pointer;
            border-radius: 5px;            
        }

        span + span {
            margin-left: 15px;
        }
    }
}