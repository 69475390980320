.home {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  &__container {
      width: 100%;
      display: flex;
      overflow-y: auto;
      overflow-x: hidden;
      justify-content: flex-start;
      // box-shadow: 0 5px 80px $color-black-opacity;
      flex-direction: column;
      &-body {
          z-index: 0;
      }
      &-header {
          margin-top: 10px;
          width: 100%;
          height: 10%;
          text-align: center;
      }
  }   
}