.modalSinistro {
    min-width: 70vw;
    b {
        text-decoration: none;
    }
    table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        border: 1px solid $color-black;
        
        thead tr th {
            border-bottom: 1px solid $color-black;
            background: $color-secondary;
            color: $color-white;
            font-size: 15px;
        }
        
        thead tr th,
        tbody tr td {
            border-right: 1px solid $color-black;
            cursor: pointer;
        }
    }
    
    textarea {
        width: 100%;
        resize: none;
        outline-color: $color-secondary;
        border: 1px solid $color-grey-light3;
        height: 60px;
        margin: 10px;
        position: relative;
        left: -10px;
        &:focus {
            border-color: $color-secondary;
            outline: none !important;
        }
    }

    .container-desc-venda {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    &__footer {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        
        span {
            width: 180px;
            height: 30px;
            cursor: pointer;
            background: $color-secondary;
            color: $color-white;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            border-radius: 20px;
        }
    }
}