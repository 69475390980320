.sugestao {
    width: 100vw;
    height: 92vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background: #fff;
    margin-top: 20px;
    
    &__header {
        display: flex;
        color: $color-secondary;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 10px;
        

        i {
            background: $color-secondary;
            color: #fff;
            font-size: 30px;
            padding: 10px;
            border-radius: 200px;
            margin-bottom: 20px;
        }
        
        span:nth-child(2) {
            margin-top: 10px;
        }
    }

    &__body {
        textarea {
            resize: none;
            width: 90vw;
            height: 40vh;
            margin-top: 10px;
            padding-left: 10px;
            padding-top: 10px;
            font-size: 15px;
        }
    }

}