.vendaOnline {
  h1 {
    font-size: 24px;
    font-weight: 600;
    color: $color-secondary;
    margin-bottom: 32px;
  }
  
  .payment_method_options {
    display: flex;
    @media (max-width: 480px) {
      flex-direction: column;
    }

    .payment_card {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      position: relative;
      padding: 8px;
      margin: 8px;
      border: 1px solid #eee;
      border-radius: 3px;
      width: 180px;
      height: 90px;
      box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.12);

      &.active {
        border: 2px solid $color-secondary;
      }

      img {
        width: initial;
        height: 45px;
        margin: auto;
        align-self: center;

        &.boleto {
          height: 26px;
        }
      }

      h2 {
        font-size: 14px;
        text-align: center;
      }

      .checkmark {
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-10px, -10px);
        border-radius: 50px;
        background-color: #fff;
        i {
          color: $color-secondary;
          font-size: 25px;
          font-weight: bold;
        }
      }
    }
  }
  .buttons {
    margin-top: 32px;
    button {
      margin: 0 16px;
    }
  }
}
