.dashboard-body {
  width: 100%;
  height: 1000px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 0;
  margin-top: 10px;
  
  .dashboard-cards {
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding-bottom: 13px;
    
    .card {
      // cursor: pointer;
      display: flex;
      flex-direction: column;
      width: 240px;
      height: 120px;
      font-family: Rubik,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
      color: $color-white;
      border-radius: 5px;
      box-shadow: 9px 5px 5px #ccc;

      .font-value {
        width: 99%;
        padding-top: 17px;
        font-size: 25px;
        font-weight: 10px;
        padding-bottom: 5px;
        border-bottom: 1.5px dotted white;
      }

      .font-information {
        padding-top: 15px;
        font-size: 17px;
        font-weight: 10px;
        padding-bottom: 10px;
      }

      i {
        position: relative;
        font-size: 40px;
        left: 180px;
        bottom: 35px;
      }
    }
  }

  .timeline-modal {
    color: rgb(33, 150, 243);
    font-size: 40px;
    position: relative;
    left: 48.5%;
    top: 26%;
    cursor: pointer;
  }

  .dashboard-graph {
    padding-top: 60px;
    height: 100px;
    border-top: 1px solid #ccc;

    .dashboard-graph-header {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 30px;
      
      span {
        font-weight: bold;
        font-size: 22px;
      }
    }
      
    .month {
      margin-top: 20px;
      width: 100%;
      max-width: 88vw;
  
      &-body {
        flex: 1;
        width: 100%;
        height: 200px;
      }

    }
  }
}


.dashboard-timeline {
  position: relative;
  top: 10px;
  border-top: 1px solid #ccc;

  .dashboard-timeline-header {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    
    span {
      position: relative;
      margin-top: 30px;
      font-weight: bold;
      font-size: 22px;
    }
  }
  .dashboard-timeline-body {
    width: 99%;
    position: relative;
    background-color: $color-white;
  }
}

.timeline-root {
  background-color: $color-white;
  
  .timeline-element-date {
    color: black;
  }

  .timeline-element-icon {
    font-size: 35px;
    color: rgb(33, 150, 243);
    padding-left: 13px;
    padding-top: 7px;
  }

}

.custom-tooltip {
  background-color: $color-white;
  padding: 20px;
  border-radius: 3px;
  border-style: solid;
  border-color: rgb(33, 150, 243);

  .custom-tooltip-header {
    font-weight: bold;
    font-size: 14px;
    border-bottom: 1.5px solid black;
  }

  .custom-tooltip-body {
    display: flex;
    position: relative;
    align-items: center;
    font-size: 13px;
    flex-direction: column;
  }
}

.modal-total-revenue {
  padding-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $color-white;

  .modal-total-revenue-body {
    background-color: $color-white;
    width: 100%;
    height: 100%;
  
    h1 {
      color: $color-secondary;
    }
  }
}