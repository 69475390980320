.step {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;

  &__timeline {
    display: flex;
    width: 94%;
    justify-content: center;
    justify-items: center;
    align-items: center;
    height: 40px;
    font-size: 20px;
    position: relative;

    &-number {
      background-image: linear-gradient(to right bottom, $color-grey-light1, $color-grey-light3);
      width: 50px;
      height: 50px;
      border-radius: 200px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      color: $color-grey-light2;

      &--active {
        background-image: linear-gradient(to right bottom, $color-primary, $color-secondary);
        color: $color-white;
      }
    }

    &-bar {
      flex: 5;
      width: 10px;
      height: 10px;
      background-image: linear-gradient(to right bottom, $color-grey-light1, $color-grey-light3);

      &--active {
        background-image: linear-gradient(to right bottom, $color-primary, $color-secondary);
        color: $color-white;
      }
    }
  }
}

.step-timeLine {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
}

.step__step1 {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;

  .image_picker {
    display: flex;
    justify-content: space-around;

    .clear {
      display: none;
    }
  }

  .line {
    display: flex;
    width: 90%;
    justify-content: center;
    align-content: center;
    align-items: center;

    .cpf-security-validation {
      &.hidden {
        opacity: 0;
      }

      border-radius: 8px;
      transition: 0.2s;
      position: fixed;
      width: 250px;
      transform: translate(220%, -140%);
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.11);
      background: rgba(255, 255, 255, 0.95);
      padding: 18px;
    }
  }

  .lineswitch {
    padding-top: 15px;
    font-weight: 700;
  }

  .switch-email,
  .popover-email,
  .switch-corresponsavel,
  .popover-corresponsavel {
    flex: 1;
    text-align: center;
    padding: 13px;
  }

  .popover-emailUcredPlan,
  .popover-corresponsavelUcredPlan {
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
  }
}
.modalService,
.modalCorresp {
  display: flex;
  width: 50vw;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 20px;
  text-align: center;

  h1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: $color-secondary;
  }

  .container-check {
    padding-top: 20px;
    input {
      width: 20px;
      height: 20px;
    }
  }

  .container-modalService {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.modalWasNotAnalysed {
  display: flex;
  width: 50vw;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 20px;
  text-align: center;

  h1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: $color-secondary;
  }

  .container-check {
    padding-top: 20px;
    input {
      width: 20px;
      height: 20px;
    }
  }

  .container-modalService {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.step__step2 {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;

  .line {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  fieldset {
    margin-top: 10px;
    padding: 10px;
    border: 2px solid $color-secondary;
    flex: 1;
    margin-right: 10px;

    legend {
      font-size: 20px;
      color: $color-secondary;
      padding: 5px 8px;
      border-radius: 20px;
      i {
        margin: 0 5px;
        font-size: 30px;
      }
    }
  }

  fieldset:focus-within {
    legend {
      animation: mymove 1s ease-in;
    }
  }

  .containerDocumentos,
  .dadosCartaoContratante,
  .dadosImovel,
  .dadosServico,
  .dadosVenda,
  .dadosContratante {
    width: 100%;
    display: flex;
  }

  .dadosCartaoContratante {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .container-card {
    display: inline-block;
  }

  .container-value-unique,
  .container-value {
    display: inline-block;
    position: relative;
    top: -20px;
  }

  .container-value {
    width: 100%;
  }

  .container-value-unique {
    width: calc(100% - 440px);
  }

  .container-dropZone {
    height: 100px;
    border: 1px dotted $color-secondary;
    text-align: center;
    vertical-align: middle;
    font-weight: 700;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .containerServices {
    display: flex;
    align-content: space-around;
    justify-items: center;
    justify-content: space-around;
    position: relative;
  }

  .line-service {
    width: 400px;
    height: 60px;
    background: $color-secondary;
    color: #fff;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
    margin-bottom: 20px;

    b {
      text-decoration: none;
    }
  }
  .line-total {
    font-size: 20px;
    b {
      text-decoration: none;
      margin-left: 5px;
    }
  }
  .line-adhesion {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-content: space-around;
    font-size: 20px;

    select {
      margin-left: 5px;
      width: 140px;
      height: 25px;
      position: relative;
      top: -2px;
    }
  }
  .line-contratante {
    display: flex;
    justify-content: flex-start !important;
    align-content: flex-start !important;
    align-items: flex-start !important;

    .label-check {
      position: relative;
      top: 5px;
    }
    .checkbox-label {
      margin: 0;
      margin-right: 10px;
    }
  }
  embed {
    width: 300px;
    height: 300px;
    padding: 10px;
  }

  .container-documentos {
    margin-top: 20px;
  }
  .file {
    position: relative;
    display: inline-block;
    i {
      cursor: pointer;
      background: $color-secondary;
      color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 400px;
      font-size: 20px;
      position: absolute;
      top: 0;
      right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      justify-items: center;
      align-content: center;
      -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.11);
      -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.11);
      box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.11);

      &:hover {
        background: $color-primary;
      }
    }
  }
  .add_contact {
    cursor: pointer;
  }

  .add_contact {
    padding-top: 35px;
    color: $color-secondary;
  }
  .remove_contact {
    cursor: pointer;
  }
  .remove {
    cursor: pointer;
    padding-top: 13px;
    text-align: center;
    color: $color-secondary;
  }

  .line-buttons {
    display: flex;
    justify-content: space-around;
    align-content: space-around;
    padding-bottom: 10px;
  }

  .btn-sendContract {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: $color-grey-light2;
    color: #fff;
    width: 200px;
    height: 45px;
    font-size: 20px;
    position: relative;
    top: 10px;
    cursor: pointer;

    i {
      margin-right: 10px;
    }
  }
}

@keyframes mymove {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
.step3 {
  .container-trial {
    padding: 20px;
    font-size: 21px;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: $color-secondary;
    color: $color-white;
    margin-bottom: 20px;
  }

  .container-print {
    span {
      display: block;
      width: 150px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      color: #fff;
      background: $color-secondary;
      cursor: pointer;
      border-radius: 20px;
      font-size: 20px;

      i {
        margin-right: 4px;
      }
    }
  }

  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-content: center;
  align-items: center;

  .title {
    color: $color-secondary;
    font-size: 30px;
    margin-bottom: 10px;
  }

  .newContainer {
    width: 90%;
    margin-top: 10px;
    font-size: 20px;

    .title {
      color: $color-secondary;
      font-size: 25px;
    }

    .item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid $color-grey-light3;
    }
  }

  .line-buttons {
    display: flex;
    justify-content: space-around;
    align-content: space-around;
    padding-bottom: 10px;
    width: 100%;
  }
}

.print-logo {
  display: none;
}

.print-area-hidden {
  display: none;
}
@media print {
  ._step * {
    display: none;
  }

  .rdstation-popup-position-bottom_right,
  .rdstation-popup-position-bottom_right * {
    display: none;
  }

  .print-area-hidden {
    display: block;
    z-index: 5;
  }

  .print-same-line {
    display: flex !important;
  }

  .print-logo {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.1;
    z-index: 999999;
    img {
      width: 400px !important;
    }
  }

  .MuiCollapse-container,
  .MuiCollapse-container * {
    display: none !important;
  }

  .print-hidden,
  .print-hidden * {
    display: none !important;
  }

  #print-area {
    position: absolute;
    overflow: scroll;
    top: -64px;
    width: 100%;
    height: auto;
    display: flex !important;
    box-shadow: none !important;
    flex-direction: column;
  }

  #print-area * {
    display: block;
  }

  @page {
    margin: 0;
  }
}

.container-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  opacity: 0.9;
  z-index: 9999 !important;
  background: black;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  pointer-events: none;
  cursor: default;
  font-size: 80px;
  color: $color-secondary;
  flex-direction: column;

  img {
    width: 30vw;
  }
}
.modalUploadContract {
  &__container {
    width: 50vw;
    height: 30vh;
    display: flex;
    &--instructions {
      border: 1px solid $color-secondary;
      padding: 10px;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-items: center;
      justify-content: center;
      width: 40vw;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      line-height: 35px;
      background: $color-secondary;
      color: #fff;

      small {
        font-size: 15px;
        text-decoration: underline;
      }
    }
  }

  &__dropzone {
    width: 40vw;
    border: 1px dotted black;
    margin-top: 10px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    height: 10vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }

  &__contract {
    position: relative;
    display: flex;
    width: 40vw;
    left: 50%;
    transform: translateX(-50%);
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin-top: 10px;

    .contract {
      position: relative;
    }

    i {
      position: fixed;
      right: 0;
      background: $color-secondary;
      color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 200px;
      display: flex;
      justify-items: center;
      align-content: center;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      cursor: pointer;
    }
    embed {
      width: 400px;
      height: 430px;
    }
  }

  &__button {
    position: relative;
    display: flex;
    width: 40vw;
    left: 50%;
    transform: translateX(-50%);
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }
}

.modalErrorTransaction {
  min-width: 50vw;
  min-height: 40vh;
}

.modalSuccessTransaction,
.modalErrorTransaction {
  .message-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .corresp-locator {
      display: flex;
      justify-content: space-around;
      .btn {
        margin: 0;
        margin-top: 20px;
        &.btn-copy-message {
          margin-right: 16px;
        }
      }
    }
  }

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  line-height: 25px;

  .title {
    font-size: 20px;
    color: $color-secondary;
  }

  .icon {
    font-size: 40px;
    color: $color-secondary;
  }
  .descMessage {
    font-size: 17px;
    font-weight: 700;
  }

  .subTitle,
  .message {
    font-size: 17px;
  }
}

.observation {
  margin-top: 16px;
  font-weight: normal;
  font-size: 18px !important;
  text-align: center;
}

.modalSuccessTransaction {
  width: 50vw !important;
  height: 25vh !important;
}

.obs-adhesion {
  color: $color-secondary;
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 25px;
  font-size: 14px;
  text-align: center;
}

.obs-adhesion-fake {
  color: $color-secondary;
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 25px;
  font-size: 14px;
  text-align: center;
  opacity: 0;
}

.typeImobContent {
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 17px;
  color: black;
}

.cpfImage {
  display: flex;
  align-content: center;
  width: 690px;
  height: 320px;
  cursor: auto;
}

.informTextStep1 {
  display: flex;
  font-size: 20px;
  margin-top: 2%;
}

.cpfInput__field {
  position: absolute;
  bottom: 24%;
  left: 35%;
  font-family: inherit;
  //   width: 20.2%;
  width: 230px;
  border: 0;
  border-color: transparent;
  border-bottom: 2px solid #009fff;
  font-size: 18px;
  color: black;
  padding: 2px 1px 1px 4px;
  background: transparent;
  transition: border-color 0.3s;

  &::placeholder {
    color: #009fff;
    font-family: inherit;
    padding: 7px;
    font-size: 20px;
    font-weight: initial;
  }
}
