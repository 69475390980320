.box {
    box-sizing: border-box;
    width: 100%;
    height: 30vh;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    background-image: linear-gradient(to right top,#00e3cf,#009fff);

       .container-price {
           width: 250px;
           height: 15vh;
           position: absolute;
           left: 60px;
           background: $color-primary;
           display: flex;
           justify-content: center;
           align-content: center;
           align-items: center;
           color: #fff;
           flex-direction: column;
           -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.11);
           -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.11);
            box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.11);
            z-index: 9999999;
            border-radius: 40px;
            background: $color-secondary;
            background-size: cover;

            i {
                font-size: 40px;
                margin-bottom: 10px;
            }
           
            .fullPrice {
               font-size: 30px;
           }
       }

       .container-body-active,
       .container-body {
            display: flex;
            width: calc(100% - 450px);
            height: 33vh;
            border-radius: 20px;
            justify-content: center;
            align-content: center;
            align-items: center;
            flex-direction: column;
            background: #fff;
            border-radius: 40px;
            position: absolute;
            right: 177px;
       }

       .container-body-active {
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.11);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.11);
         box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.11);
         animation: mymove 2s ease-in;
       }
       
       .product-title {
           font-size: 30px;
           color: $color-secondary;
           margin-top: 10px;
       }

       .data {
            .item {
                width: 700px;
                display: flex;
                justify-content: space-between;
                align-content: space-between;
            }
            .data-values {
                display: flex;
                font-size: 20px;
                margin: 5px 0;
                .label {
                    margin-right: 10px;
                }
                .value {
                    color: $color-secondary;
                    font-weight: 700;
                }
            }
       }

       .footer {
           position: relative;
           padding-top: 10px;
           span {
               display: flex;
               justify-content: center;
               align-content: center;
               align-items: center;
               width: 180px;
               height: 28px;
               cursor: pointer;
               color: #fff;
               background-image: linear-gradient(to right top,#00e3cf,#009fff);
               border-radius: 10px;
               font-weight: 700;
           }
       }
}

.swiper-slide {
    height: 100% !important;
}

.btn-active {
    background: $color-primary !important;
    background-image: none !important;
}

.customized-swiper-container {
    width: 100%;
    height: 37vh;
    overflow: hidden;
}

.next-prod,
.prev-prod {
    font-size: 40px;
    color: #fff;
}


.next-prod {
    position: absolute;
    top: 24%;
    right: 40px;
    z-index: 9999999;
}


.prev-prod {
    position: absolute;
    top: 54%;
    right: 40px;
    z-index: 999999;
}


@keyframes mymove {
    0%   {
        transform: scale(1)    
    }
    
    50% {
        transform: scale(1.1)
    }

    100% {
        transform: scale(1)
    }
  }