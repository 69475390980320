.footer-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 25px;
}

.inner-shadow {
  font: bold 14px 'roboto', sans-serif;
  background-color: rgb(126, 126, 126);
  color: transparent;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.658);
  background-clip: text;
}

.heart {
  position: absolute;
  width: 100px;
  height: 90px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin-top: -47px;
  margin-left: -50px;
}
.heart:before,
.heart:after {
  position: absolute;
  content: '';
  left: 50px;
  top: 0;
  width: 50px;
  height: 80px;
  background: #009fff;
  border-radius: 50px 50px 0 0;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}
.heart:after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}
.love2 {
  animation: beating 0.5s linear infinite alternate;
}
.love1:after,
.love1:before {
  background-color: #009fff;
}

@keyframes beating {
  0% {
    transform: scale(0.18);
  }
  100% {
    transform: scale(0.25);
  }
}
@keyframes heart-animation {
  0% {
    transform: scale(0.2);
  }
  25% {
    transform: scale(0.25);
  }
  50% {
    transform: scale(0.3);
  }
  75% {
    transform: scale(0.4);
  }
  100% {
    opacity: 0;
    transform: scale(0.6);
  }
}
