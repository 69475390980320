.novo-revendedor {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: lighten($color-secondary, 10);

  .logo {
    width: 60vw;
    height: 100vh;
    position: fixed;
    top: 39%;
    left: 27%;
  }

  .card {
    width: 25%;
    height: 50%;
    border-radius: 10px;
    background: $color-white;
    overflow: none;
    
    .step-container {
      width: 100%;
      
      .login {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 90%;
        h1 {
          position: relative;
          display: flex;
          flex: 1;
          flex-direction: column;
          font-size: 25px;
          margin-top: 50px;
          margin-left: 30px;
          left: 1%;
          color: $color-secondary;
        }
        .login-item {
          position: relative;
          padding-top: 5px;
          width: 80%;
          left: 17%;
        }
        .login-button {
          padding-top: 20px;
          position: relative;
          display: flex;
          flex-direction: column;
          width: 90px;          
          left: 42%;
        }
      }
    }
  }
}

.dados-usuario {
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
  margin-top: 10px;
  flex-direction: column;
  height: 300px;
  .header {
      border-bottom: none;
      height: 2vh;
      font-size: 15px;
  }
  
  .body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      .line {
          display: flex;
          width: 100%;
          .item {
              flex: 1;
              margin-left: 5px;
          }
      }
      .progress-container {
          padding-top: 20px;
          right: 10px;
          bottom: -80px;
          display: flex;
          flex: 1;
          flex-direction: column;
          width: 10%;
      }
  }
  
  .loading {
      width: 40px;
      float: right;
  }
}

.container-password {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.container-password i {
  position: relative;
  top: -5px;
  font-size: 20px;
  cursor: pointer;
}