.loginRevendedor {
  display: flex;
  &__container-image {
      background-blend-mode: soft-light;
      width: 60vw;
      height: 100vh;
      background-image: linear-gradient(to right bottom, $color-primary, $color-secondary),url(https://i.ibb.co/H71G6fx/Adobe-Stock-159364568-1-jpeg.jpg);
      background-size: cover;
  }

    &_reseller {
        font-size: 20px;
        font-weight: bold;
        position: absolute;
        top: 38vh;
    }

  &__container-form {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      width: 40vw;
      background: $color-white;
  }

  &__form--header {
      p {
          letter-spacing: 3px;
          word-spacing: 4px;
      }
  }
  
  &__form {
      text-align: center;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-content: center;
      &-image {
          height: 134px;
      }
  }

  &__form--body {
      width: 60%;
      margin-top: 20px;
  }

  .container-password {
      width: 100%;
      display: flex;
      align-items: flex-end;
  }

  .container-password i {
      position: relative;
      top: -5px;
      font-size: 20px;
      cursor: pointer;
  }

  .forgot {
      margin-top: 10px;
      cursor: pointer;
      color: $color-secondary;
      font-weight: 700;
      font-size: 20px;
  }
}

.modal-forgot {
  width: 500px;
  height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;

  b {
      text-decoration: none;
      color: $color-secondary;
      font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .login { 
      &__container-form {
          width: 100vw;
          height: 100vh;
          background-image: linear-gradient(to right bottom, $color-primary, $color-secondary), url(https://images.unsplash.com/photo-1426043713232-fdb3a1bec18f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1100&q=80);
          background-blend-mode: soft-light;
          background-size: cover;
          background-position: center;
          background-color: transparent;
      }
  
      &__container-image {
          display: none;
      }
  
      &__form {
          background: $color-white;
          border-radius: 10px;
          padding: 30px;
          width: 96%;
          box-shadow: 0px 20px 30px $color-black-opacity;
          
          &--body {
              width: 93%;
          }
      }
  }
}