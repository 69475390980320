#client-detail {
  margin-top: 80px;
  padding-top: 32px;

  .card-container {
    position: relative;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    height: 240px;
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    .card-background {
      border-radius: 16px 16px 0 0;
      height: 200px;
      width: calc(100% + 32px);
      margin-right: -16px;
      margin-left: -16px;
      background-size: contain;
    }

    .avatar-name {
      display: flex;
      .avatar {
        position: absolute;
        width: 100px;
        height: 100px;
        transform: translateY(-80px);
        border: 2px solid #fff;
      }
      .user {
        position: absolute;
        transform: translateY(-60px);
        overflow: hidden;

        .name,
        .cpf {
          color: #fff;
          margin-left: 110px;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .name {
          font-size: 14pt;
        }
      }
    }

    .config-icon {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
    }

    .tabs {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }
}

#edit-credit-card-modal {
  .content {
    width: 338px;
  }
}
