.planos__container {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .planos__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}
