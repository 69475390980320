.loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #009fff; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.5s linear infinite;
    margin: auto;
    margin-top: 20px;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}