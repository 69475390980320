.modalCancelTransaction {
  border-radius: 4px;
  .confirmation {
		display: flex;
		flex-direction: column;
		align-items: center;
    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 18px;
    }

    p {
      font-size: 16px;
      margin-top: 18px;
      max-width: 400px;
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: center;
      :last-child {
        margin-left: 16px;
      }
    }
  }
}
