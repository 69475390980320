.servicos {
    .disabled {
        background-color: #ddd !important;
        color: #666 !important;
    }
    height: 92vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    &__container {
        background: $color-white;
        position: relative;
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: flex-start;
        overflow-y: auto;
        overflow-x: hidden;
        align-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        &-header {
            margin-top: 10px;
            width: 100%;
            height: 10%;
            text-align: center;
        }
        &-body {
            padding-top: 32px;
        }
    }
    &__floating-menu {
        position: absolute;
        right: 32px;
        top: 36px;
    }

    .img-table {
        margin-top: 5px;
        width: 20px;
        height: 20px;
    }

    &__renda {
       display: flex;
       flex-direction: column;
       width: 87%; 

       p {
           font-size: 20px;
           margin-bottom: 10px;
       }
    }
}

.modalDetailService {
    width: 40vw;
    height: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    input[type="range"] {
        width: 30vw;
    }
}

.modal_over_container {
    width: 80%;

    .total_fee {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-content: center;
        align-items: center;
        justify-content: center;
    
        b {
            margin-top: 5px;
            text-decoration: none;
            font-size: 20px;
        }

        span {
            font-size: 20px;
            margin-top: 5px;
        }
    }
}

.btn-save-over {
    width: 50%;
    height: 40px;
    background: $color-secondary;
    color: $color-white;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 40px;
    display: flex;
    position: relative;
    justify-content: center;
    align-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
}