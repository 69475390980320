#send-document-signers.pageContainer {
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
  margin-top: 5vh;

  .btn-documents-back {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;

    .btn-back {
      justify-content: flex-start;
    }

    .btn-documents {
      justify-content: flex-end;
    }
  }

  .send-document-title {
    font-size: 23px;
    color: #009fff;
    display: flex;
    justify-content: center;
  }

  .client-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    :nth-child(1) {
      font-weight: bold;
    }
  }
}

.modalConfirmSendDocuments {
  border-radius: 8px;
  width: 700px;

  .main-title {
    font-size: 20pt;
    margin-bottom: 16px;
  }

  .body {
    li {
      display: flex;
      justify-content: center;
    }

    h3 {
      text-decoration: none;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }
  }

  .documents .files-preview {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 140px;
    margin-top: 20px;
    .file {
      display: flex;
      position: relative;
      width: 100px;
      margin: 0 10px;
      flex-direction: column;
      height: 120px;
      input {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        transform: translateY(100%);
        background: none;
        border: none;
        text-align: center;
        font-weight: 700;
      }
      embed {
        width: 100%;
        height: 100%;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    button:nth-child(2) {
      margin-left: 8px;
    }
  }
}

#send-document-signers .novoLocador {
  max-height: 88vh;
  padding: 48px;
  margin-bottom: 180px;
  overflow: auto;
  margin: auto;
  max-width: 1500px;

  .btn-documents-back {
    .btn-back {
      font-size: 20px;
      color: #009fff;
      display: flex;
      align-items: center;
      width: 100%;
      margin-left: 0px;
      cursor: pointer;
    }
    .btn-documents {
      font-size: 20px;
      color: #009fff;
      display: flex;
      align-items: center;
      width: 100%;
      margin-left: 0px;
      cursor: pointer;
    }
  }

  .download-contracts {
    color: $color-secondary;
    font-size: 18pt;
  }

  .btn-new-document-send {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  .new-field {
    margin: 8px;
    height: 50px;
    border-radius: 50px;
    background: $color-secondary;
    font-size: 14pt;
    width: 250px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    color: #fff;
    align-self: center;
  }

  .row {
    justify-content: flex-end;

    button {
      margin-left: 8px;
      height: 42px;
    }
  }

  .signers-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;

    .remove-signer {
      position: relative;
      height: 0;
      div {
        cursor: pointer;
        color: $color-secondary;
        border: 2px solid $color-secondary;
        border-radius: 50px;
        background-color: #fff;

        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        width: 35px;
        height: 35px;
        margin-left: auto;
        transform: translate(-10px, -49px);
      }
    }

    .space {
      flex: 0.04;
    }

    fieldset {
      flex: 0.5;
      margin: 8px;
    }

    fieldset.disabled {
      border: 2px solid rgb(190, 190, 190);
      legend {
        color: rgb(190, 190, 190);
      }
    }

    @media (min-width: 1490px) {
      justify-content: flex-start;
    }
  }
}
