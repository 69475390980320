.aluguel {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    margin-top: 5vh;
    background: #fff;

    &__header {
        padding-top: 20px;
        color: $color-secondary;
    }

    &__body {

        &--instrucao {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            color: $color-secondary;
            margin-top: 10px;
        }
        
        &--dadosVenda,
        &--dadosLocatario {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
        }
        
        .line {
            display: flex;
        }
        
        fieldset {
            margin-top: 10px;
            padding: 10px;
            border: 2px solid $color-secondary;
            flex: 1;
            margin-right: 10px;
            width: 90vw;
            
            .container-select {
                min-width: 400px;
                display: flex;
                flex-direction: column;
                position: relative;
                top: 16px;
                
                select {
                    width: 90%;
                    height: 38px;
                }
            }
    
            legend {
                font-size: 20px;
                color: $color-secondary; 
                padding: 5px 8px;
                border-radius: 20px;
                i {
                    margin: 0 5px;
                    font-size: 30px;
                }
            }
        }
    
        fieldset:focus-within {
            legend {
                animation: mymove 1s ease-in;
            }
        }    
    }

    &__obs {
        width: 90vw;
        font-size: 13px;
        color: $color-secondary;
        text-align: center;
        margin-top: 10px;
    }

    &__footer {
        padding-bottom: 20px;
    }
    
    .btn-voltar {
        font-size: 20px;
        color: $color-secondary;
        position: relative;
        left: 20px;
        top: 10px;
    }
}

.restrictPage {
    width: 100vw;
    height: 90vh;
    background: #fff;
    margin-top: 10vh;

    .header {
        border: none;
        color: $color-secondary;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .body {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        margin-top: 30px;
        flex-direction: column;
    
    .message {
        display: flex;
        flex-direction: column;
        i {
            margin-top: 20px;
            color: $color-secondary;
            font-size: 120px;
        }
    }
    }
}

@keyframes mymove {
    0%   {
        transform: scale(1)    
    }
    
    50% {
        transform: scale(1.1)
    }

    100% {
        transform: scale(1)
    }
  }
