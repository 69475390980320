.mudarCartao {
  background: #fff;
  height: 100vh;
  padding-bottom: 20px;
  overflow-y: auto;
  
  .responsive-row {
      flex-direction: row;
  }

  .card-item {
      max-width: 100vw;
  }
  
  &--header {
      display: flex;
      background: white;
      width: 100vw;
      padding-left: 20px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 30px;

      img {
          width: 150px;
      }
  }
  
  .title {
      color: $color-secondary;
      font-weight: 600;
      letter-spacing: 2px;
      word-spacing: 3px;
      width: 100%;
      font-size: 20px;
  }
  .row {
      justify-content: space-between;
      align-content: center;
      align-items: center;
  }

  .item {
      margin: 10px;
  }

  input {
      min-width: 222px;
  }

  &--body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
  }

  .rccs__cvc {
      visibility: unset;
      left: 64% !important;
  }

  .rccs__cvc::after{
      visibility: hidden;
  }

  .container-venda {
      flex-direction: column;
      text-align: center;
      padding: 20px;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 40px;
      border-radius: 20px;
      border: 1px solid #ccc;
      width: 90vw;
  }

  .value {
      color: $color-secondary;
  }

  .logo-erro {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
  }

  .item {
      flex: 1;
  }
  .container-totalvalue {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      padding: 10px 0;
      font-weight: 700;
      width: 100%;
      flex-direction: column;
      font-size: 16px;

      span {
          color: $color-secondary;
      }
  } 
  .container-card {
      margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .responsive-row {
      flex-direction: column !important;
  }

  .card-item {
      max-width: 85vw !important;
  }

  .vendaOnline--body {
      height: auto !important;
  } 
}