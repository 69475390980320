.consumoPlano {
    width: 100vw;
    height: 92vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    &__container {
        background: $color-white;
        position: relative;
        width: 90%;
        height: 90%;
        display: flex;
        justify-content: flex-start;
        overflow-y: auto;
        overflow-x: hidden;
        // box-shadow: 0 5px 80px $color-black-opacity;
        flex-direction: column;
        border-radius: 10px;
        &-header {
            margin-top: 10px;
            width: 100%;
            height: 10%;
            text-align: center;
        }
        &-body {
            padding-top: 32px;
        }
    }
    &__floating-menu {
        position: absolute;
        right: 32px;
        top: 36px;
    }
}