.newServiceUcredPlan {
  margin-top: 5vh;

  .newServiceUcredPlan__container-header {
    h1 {
      text-align: center;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;

    &-leftData {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      
      &-inputs {
        padding: 20px;

        .spanStyle {
          color: $color-secondary;
          font-weight: bold;
          font-size: 17px;
          margin-top: 20px;
        }
      }

      &-selects {
        margin-top: 20px;
        padding: 20px;

        &-header {
          p {
            font-size: 20px;
            font-weight: bold;
          }
        }

        &-container {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          align-content: center;
          justify-content: center;
          align-items: center;
          padding: 20px;

          p {
            position: relative;
            font-size: 17px;
            right: 17px;
            margin-bottom: 5px;
            color: $color-secondary;
            font-weight: bold;
          }

          .item {
            margin-right: 20px;
            margin-left: 40px;
          }

          .selectStyle {
            background-color: #fff;
            color: #009fff;
            width: 90px;
            height: 45px;
            border-radius: 20px;
            border-color: #009fff;
            border-width: medium;
            font-size: 17px;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
          }
        }
      }
    }

    &-rightData {
      margin-top: 17px;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding: 0px 0px 60px;

        .title {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 20px;
        }

        .content {
          position: relative;
          font-weight: bold;
          color: $color-secondary;
          font-size: 20px;
        }
      }
    }
  }
}

.verticalLine {
  border-left: 3px solid #ccc;
  height: 410px;
  position: absolute;
  left: 50%;
  margin-left: 27px;
  top: 30%;
}

.saveButton {
  position: absolute;
  left: 92%;
  top: 87%;
}

.voltar {
  font-size: 20px;
  color: $color-secondary;
  display: flex;
  width: 100%;
  margin-left: 20px;
}

.confirmationModal {
  .modal {
    width: 35vw;
    height: 68vh;
    display: flex;
    flex-direction: column;

    h1 {
      width: 100%;
    }

    &-container {
      width: 100%;
      height: 100%;

      &-data {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;

        &-row {
          display: flex;
          flex-direction: column;

          &-title {
            margin-top: 5px;
            font-size: 25px;
            display: flex;
            justify-content: center;
            font-weight: bold;
          }

          &-content {
            margin-top: 5px;
            font-size: 22px;
            font-weight: bold;
            display: flex;
            justify-content: center;
            color: $color-secondary;
            border-bottom: 2px solid #ccc;
          }
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: center;
    }
  }
}