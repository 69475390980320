.modalSallesDetail {
    min-width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 18px;
    text-align: center;

    .container {
        padding: 10px;
        display: flex;
        flex-direction: column;
        min-height: 250px;
        justify-content: center;
    }

    .switch-buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid $color-secondary;

        .button {
            width: 200px;
            cursor: pointer;
            border: 1px solid $color-secondary;
            border-bottom: none;
            border-radius: 4px 4px 0 0;
            padding: 8px;
            margin: 0 2px 0 2px;
            flex: 1;

            &.active {
                background-color: $color-secondary;
                color: #fff;
            }
        }
    }
    
    b {
        text-decoration: none;
    }

    h3 {
        background: $color-secondary;
        width: 100%;
        color: $color-white;
        padding: 5px 0;
    }
    
    .client-container,
    .salles-container,
    .ucred-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .client-container,
    .salles-container {
        margin: 10px 0;
    }


    .client-container--header,
    .salles-container--header,
    .ucred-container--header {
        width: 100%;
    }
}