.contract-clicksign {
  height: 80vh;
  width: 80%;
  border-radius: 4px;
  padding: 0 !important;

  iframe {
    border: none;
    border-radius: 4px;
  }

  @media (max-width: 480px) {
    width: 95%;
  }

  #contract-click-sign {
    border: none;
    height: 100%;
  }
}

.vendaOnline {
  background: #fff;
  max-height: 100vh;
  height: 100vh;
  padding-bottom: 20px;
  overflow-y: auto;

  .error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60vh;

    h1 {
      width: 90%;
      margin: 0 auto;
      font-weight: lighter;
      font-size: 28px;
    }
    .contract {
      max-width: 200px;
      align-self: center;
    }

    .images {
      width: fit-content;
      display: flex;
      position: relative;
      margin: 0 auto;
      .image-error {
        width: 50px !important;
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translate(70%, 20%);
      }

      .image-house {
        width: 150px !important;
      }
    }
  }

  .card-item {
    max-width: 100vw;
  }

  .select-input {
    max-width: 180px;
    margin: 0 auto;
  }

  &--header {
    display: flex;
    background: white;
    width: 100vw;
    padding-left: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;

    img {
      width: 150px;
    }
  }

  .title {
    color: $color-secondary;
    font-weight: 600;
    text-align: center;
    margin-bottom: 16px;
    width: 100%;
    font-size: 24px;
  }
  .column {
    flex-direction: column;
  }
  .row {
    .confirm {
      margin-bottom: 46px;
    }
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .item {
    display: flex;
    flex-direction: column;
    margin: 10px;
    .label {
      font-size: 18px;
      font-weight: normal;
      color: #585858;
    }

    span.item-value {
      font-size: 18px;
    }
  }

  &--body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .rccs__cvc {
    visibility: unset;
    left: 64% !important;
  }

  .rccs__cvc::after {
    visibility: hidden;
  }

  .container-venda {
    flex-direction: column;
    text-align: center;
    padding: 20px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 40px;
    border-radius: 10px;
    border: 1px solid #ccc;
    width: 90vw;
    span {
      color: $color-secondary;
    }
  }

  .logo-erro {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .item {
    flex: 1;
  }
  .container-totalvalue {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 10px 0;
    font-weight: 700;
    width: 100%;
    flex-direction: column;
    font-size: 24px;

    span {
      color: $color-secondary;
    }
  }
  .container-card {
    margin-bottom: 30px;
    max-width: 90%;

    .card-data {
      max-width: 100%;
      .row {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.modalRevision {
  border-radius: 4px;
  .revision-title {
    font-size: 20px;
    text-align: center;
  }

  .revision-subtitle {
    font-size: 18px;
    text-align: center;
  }

  .revision-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    :first-child {
      margin-top: 8px;
    }

    &.total-value {
      font-weight: bold;
      text-align: center;
      label {
        font-size: 22px;
      }
      span {
        font-size: 22px;
      }
    }

    label {
      font-size: 16px;
      text-align: center;
    }
    span {
      font-size: 16px;
      color: $color-secondary;
      text-align: center;
    }
  }
  .row {
    justify-content: center;
  }
}

@media (min-width: 920px) {
  .vendaOnline {
    .column-container {
      display: flex;
      justify-content: center;

      .spacer {
        width: 126px;
      }
    }

    .container-card {
      display: flex;
      width: 100%;
      flex: 1;

      .row.first .input {
        margin-top: 2px;
      }

      .card-image {
        margin-right: 32px;
      }
      .card-data {
        width: 100%;
        .multiple-input {
          position: relative;
          display: flex !important;
          flex-direction: row !important;
        }
      }
    }
  }
}
