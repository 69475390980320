#feedback {
  height: 100vh;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .icons {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
    }

    .header-comp {
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 22px;
        margin: 20px;
        font-weight: bold;
      }
    }

    .form-items {
      padding: 16px;
    }

    .next {
      border-radius: 0 0 26px 26px;
      height: 62px;
      width: 100%;
      font-size: 14px;
    }

    .container-card {
      background-color: #fff;
      border-radius: 26px;
      width: 500px;
      box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.7);
      justify-content: space-around;
      max-width: 90%;
    }
  }

  input {
    all: initial !important;
  }
  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: initial;
    left: initial;
  }
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    position: initial;
    padding-left: initial;
    cursor: initial;
    line-height: initial;
    display: initial;
    color: initial;
  }
  [type='radio']:checked + label:before,
  [type='radio']:not(:checked) + label:before {
    content: '';
    position: initial;
    left: initial;
    top: initial;
    width: initial;
    height: initial;
    border: initial;
    border-radius: initial;
    background: initial;
  }
  [type='radio']:checked + label:after,
  [type='radio']:not(:checked) + label:after {
    content: '';
    width: initial;
    height: initial;
    background: initial;
    position: initial;
    top: initial;
    left: initial;
    border-radius: initial;
    -webkit-transition: initial;
    transition: initial;
  }
  [type='radio']:not(:checked) + label:after {
    opacity: initial;
    -webkit-transform: initial;
    transform: initial;
  }
  [type='radio']:checked + label:after {
    opacity: initial;
    -webkit-transform: initial;
    transform: initial;
  }
}
