.vendaOnline {
  #boleto-to-copy {
    display: none;
  }

  .back {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: space-between;
    .step-back {
      @media (max-width: 480px) {
        display: none;
      }

      display: flex;
      align-items: center;
      cursor: pointer;
      color: $color-secondary;
      font-size: 12pt;
      transform: translateY(-16px);
      position: absolute;
    }
    div {
      width: 50px;
      margin-left: 16px;
    }
  }

  .boleto-btn-container {
    display: flex;
    flex-direction: column;

    align-items: center;

    > .button {
      &.button-back {
        @media (min-width: 481px) {
          display: none;
        }
      }
      margin: 0 auto;
    }

    .boleto-btn-flex {
      display: flex;
      flex-direction: row;
      @media (max-width: 512px) {
        flex-direction: column;
      }
    }

    .button {
      width: 320px;
      margin: 4px;
    }
  }
}

.ucred-terms {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 500px;

  h1 {
    font-size: 24px;
    max-width: 85%;
    margin: 0 auto 24px auto;
    font-weight: lighter;
  }

  .confirmation {
    display: flex;
    flex-direction: column;

    padding: 16px 0 0 0;

    .checkbox-label {
      margin: 0;
    }
    
    .checkbox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 500px){
        flex-direction: column;
        align-items: flex-start;

        a {
          margin-top: 16px;
        }
      }

      .check-label {
        display: flex;
        align-items: center;
      }

      label {
        margin-left: 8px;
      }
    }
  }
}
