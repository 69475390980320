.parcel-column {
  flex-direction: row;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}
#recurrence-payment {
  background: #fff;
  border-radius: 26px;
  max-width: 95%;

  .container {
  }

  .input {
    margin-bottom: 8px;
  }
  .next-step {
    display: flex;
    .back {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 0 0 0 26px;
      height: 62px;
      width: 100%;
      font-size: 14px;
    }

    .next {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 0 0 26px 0;
      height: 62px;
      width: 100%;
      font-size: 14px;
    }
  }
  .columns {
    display: flex;
    max-height: 60vh;
    overflow: auto;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
}
