#navbar {
  margin-top: 80px;
  width: 300px;
  transition: 0.5s;
  z-index: 3;
  position: fixed;
  background: #fff;
  left: 0;
  top: 0;
  height: 100vh;
  box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, 0.05);

  .wrapper {
    padding: 0px 16px;

    .submenu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 16px;

      .item {
        margin-bottom: 8px;
        padding: 8px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        border-radius: 8px;
        text-transform: initial;
      }
    }

    .user {
      justify-content: flex-start;
      border-radius: 16px;
      padding: 4px 16px;
      max-width: 268px;
      margin-bottom: 8px;

      .name {
        margin-left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .company {
        margin-left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 10pt;
      }
    }

    .menu {
      justify-content: flex-start;
      border-radius: 16px;
      padding: 8px 16px;
      width: 100%;
      background: #fafafa;
      margin-bottom: 8px;
      text-transform: initial;
    }
  }
}
