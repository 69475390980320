#card-1 {
  h2 {
    text-transform: uppercase;
    transition: all 0.3s;
  }

  b {
    text-decoration: underline;
  }

  h1 {
    font-size: 3rem;
    text-align: center;
  }

  header {
    margin-bottom: 3rem;
  }

  /* CARD STYLES */

  &.card {
    width: 28rem;
    margin: 2rem auto;
    padding-left: 8px;
    padding-right: 8px;
    background: #eee;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
  }

  &.card:hover {
    box-shadow: 0 0 4rem rgba(0, 0, 0, 0.3);
  }

  &.card-active {
    box-shadow: 0 0 4rem #70a1ffff !important;
  }

  .card-heading {
    padding: 4rem 1rem 1rem;
    margin-top: -2rem;
    margin-bottom: 4rem;
    transform: skewY(-7deg);
    background: #444;
    color: #eee;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  }

  .card-heading > * {
    transform: skewY(7deg);
  }

  .card-heading h2 {
    font-size: 3rem;
    text-align: center;
  }

  .card-heading span {
    text-align: center;
    display: block;
    // max-width: 8rem;
    background-image: linear-gradient(#ff7f50, #ff6348);
    padding: 0.2rem 0.5rem;
    margin: 0.5rem auto;
    border-radius: 4px;
    color: white;
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  &.card-active,
  &.card:hover .card-heading span {
    opacity: 1;
  }

  &.card .card-heading span {
    background-image: linear-gradient(#70a1ff, #1e90ff);
  }

  .card-features {
    width: 80%;
    margin: auto;
    text-align: center;
  }

  .card-features li {
    text-transform: uppercase;
    font-size: 1.3rem;
    display: block;
    list-style: none;
    padding: 1rem;
    border-radius: 4px;
  }

  &.card li:nth-of-type(even) {
    background: rgba(0, 0, 0, 0.05);
  }

  .card-price {
    text-align: center;
    font-size: 1.4rem;
    margin: 3rem auto;
  }

  .card-price span {
    display: block;
    font-size: 3rem;
    font-weight: 800;
  }

  .card-price button {
    margin: 2rem auto 1rem;
    border: none;
    background: #444;
    font-size: 1.2rem;
    color: #fff;
    padding: 1rem 2rem;
    border-radius: 4px;
    transition: background 0.4s;
  }

  &.card:hover h2,
  &.card:hover .card-price span {
    background-image: linear-gradient(to bottom right, #70a1ff, #1e90ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 2rem;
  }
  &.card-active h2,
  &.card-active .card-price span {
    background-image: linear-gradient(to bottom right, #70a1ff, #1e90ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 2rem;
  }

  &.card-active,
  &.card:hover .card-price span {
    margin-bottom: 0;
  }

  .card-price button:hover {
    /*   transform: translateY(-.5rem) scale(1.05); */
    background: #111;
  }

  @media (min-width: 700px) {
    html {
      font-size: 70%;
    }

    body {
      overflow: hidden;
    }

    .container {
      margin: auto;
      position: relative;
      max-width: 1000px;
    }
  }
}
