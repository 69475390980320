.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .col {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
}

.row-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 20px;
}

.col-12 {
    grid-column: span 12;
}
.col-11 {
    grid-column: span 11;
}
.col-10 {
    grid-column: span 10;
}
.col-9 {
    grid-column: span 9;
}
.col-8 {
    grid-column: span 8;
}
.col-7 {
    grid-column: span 7;
}
.col-6 {
    grid-column: span 6;
}
.col-5 {
    grid-column: span 5;
}
.col-4 {
    grid-column: span 4;
}
.col-3 {
    grid-column: span 3;
}
.col-2 {
    grid-column: span 2;
}
.col-1 {
    grid-column: span 1;
}

@media (min-width: 641px) {
    .col-md-12 {
        grid-column: span 12;
    }
    .col-md-11 {
        grid-column: span 11;
    }
    .col-md-10 {
        grid-column: span 10;
    }
    .col-md-9 {
        grid-column: span 9;
    }
    .col-md-8 {
        grid-column: span 8;
    }
    .col-md-7 {
        grid-column: span 7;
    }
    .col-md-6 {
        grid-column: span 6;
    }
    .col-md-5 {
        grid-column: span 5;
    }
    .col-md-4 {
        grid-column: span 4;
    }
    .col-md-3 {
        grid-column: span 3;
    }
    .col-md-2 {
        grid-column: span 2;
    }
    .col-md-1 {
        grid-column: span 1;
    }

    @media(min-width: 1008px) {
        .col-lg-12 {
            grid-column: span 12;
        }
        .col-lg-11 {
            grid-column: span 11;
        }
        .col-lg-10 {
            grid-column: span 10;
        }
        .col-lg-9 {
            grid-column: span 9;
        }
        .col-lg-8 {
            grid-column: span 8;
        }
        .col-lg-7 {
            grid-column: span 7;
        }
        .col-lg-6 {
            grid-column: span 6;
        }
        .col-lg-5 {
            grid-column: span 5;
        }
        .col-lg-4 {
            grid-column: span 4;
        }
        .col-lg-3 {
            grid-column: span 3;
        }
        .col-lg-2 {
            grid-column: span 2;
        }
        .col-lg-1 {
            grid-column: span 1;
        }
    }
}
