.avisos {
    width: 100vw;
    height: 92vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    &__container {
        background: $color-white;
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 10px;
        &-header {
            margin-top: 10px;
            width: 100%;
            height: 10%;
            text-align: center;
        }
        &-body {
            padding: 0 32px;
            align-content: center;
            align-items: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
    .container-new {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        justify-items: flex-end;
        align-items: flex-end;
    }  

    .aviso-header {
        border-top: 1px solid $color-grey-light2;
        font-size: 20px;
        line-height: 35px !important;
        color: $color-white;
        background: $color-secondary;

        b {
            text-decoration: none;
        }
    }
    .avisos-timeline {
        width: 100%;
        padding: 5px;
        margin-top: 10px;
        min-height: 50vh;
        height: 50vh;
        overflow: auto;
        
        .aviso {
            display: flex;
            border-bottom: 1px solid $color-grey-light2;
            line-height: 20px;
        }

        .aviso-date {
            display: flex;
            flex: 2;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            border-right: 1px solid $color-grey-light2;
            border-left: 1px solid $color-grey-light2;
            min-width: 110px;
            
            b {
                text-decoration: none;
            }
        }

        .aviso-body {
            display: flex;
            flex: 6;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            font-weight: 700;
            border-right: 1px solid $color-grey-light2;
        }
    }
}