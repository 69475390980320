#acompanhamento {
  margin-top: 80px;
  padding-top: 32px;

  .chart-container {
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 16px;

    .data-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .data {
        font-size: 18pt;
      }

      .data-title {
        font-size: 10pt;
      }
    }

    .title {
      padding: 24px 32px;
    }
  }
}
