.trial-poupup-await {
    display: flex;
    width: 100%;
    background: $color-white;
    margin-top: 10px;
    padding: 3px;
    background-image: linear-gradient(to right bottom, #f6e58d, #ffbe76);
    cursor: pointer;
    box-shadow: 0 2px 12px $color-black-opacity;

    &__container {
        display: flex;
        justify-content: space-around;
        align-content: space-around;
        width: 100%;
        background: $color-white;
    }
    
    &__header {
        flex: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-content: center;
        justify-items: center;
        min-width: 80px;
        background-image: linear-gradient(to right bottom, #f6e58d, #ffbe76) !important;
        
        span {
            font-size: 60px;
            color: $color-white;
            position: absolute;
            top: 50%;
            transform: translateY(-40%);
        }
    }

    &__body {
        flex: 4;
        display: flex;
        justify-content: center;
        align-content: center;
        justify-items: center;
        text-align: center;
        font-size: 15px;
        flex-direction: column;
        padding: 5px 0;

        b {
            background-image: linear-gradient(to right bottom, #f6e58d, #ffbe76);
            color: transparent;
            background-clip: text;
            text-decoration: none;
        }

        small {
            font-size: 8px;
            font-weight: 700;
        }
    }
    
    &__close {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-content: flex-end;
        justify-items: flex-end;
        padding-right: 10px;
        text-decoration: none;
        font-size: 30px;

        p {
            position: relative;
            right: 5%;
            top: 3%;
        }
    }
}

.modalBoleto {
    .container-modal {
        width: 100%;
        height: 80vh;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        
        .pdf-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
        
            .title {
                flex: 1;
                color: $color-secondary;
            }

            .body {
                flex: 8;
                width: 100%;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;

                embed {
                    width: 80%;
                    height: 100%;
                }
            }

            .footer {
                flex: 1;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;

                span {
                    width: 200px;
                    height: 40px;
                    background: $color-secondary;
                    color: $color-white;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    border-radius: 20px;
                    box-shadow: 0 5px 3px $color-black-opacity;
                    cursor: pointer;
                    transition: all .2s ease;
                    
                    &:hover {
                        box-shadow: 0 3px 1px $color-black-opacity;
                        background: darken($color-secondary, 5%);
                    }
                }
            }
        }
    }

    .carregando {
        .message {
            font-size: 40px;
            letter-spacing: 2px;
            word-spacing: 3px;
            text-align: center;
            color: $color-secondary;
            position: relative;
            top: -80px;

            i {
                font-size: 50px;
            }
        }
        .load {
            .loader {
                width: 130px;
                height: 130px;
            }
        }
    }
}

.alert-notify {
    margin-bottom: 20px;
   .notify-title {
       display: flex;
       width: 100%;
       justify-content: center;
       align-content: center;
       align-items: center;
       justify-items: center;
       margin-top: 20px;
   }

   .line {
    margin: 10px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    span {
        border-bottom: 1px solid $color-primary;
        margin-left: 20px;
        height: 30px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-content: flex-end;
        align-items: flex-end;
        justify-items: flex-end;
        min-width: 180px;
    }
    b {
           text-decoration: none;
           font-weight: 400;
           color: $color-grey-light;
           font-size: 10px;
           position: absolute;
           top: 1px;
           left: 1px;
       }
   }
}