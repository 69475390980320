.detalhado {
  display: flex;
  background: #fff;
  flex-direction: column;
  height: 92vh;
  .responsive_table {
    max-width: 100vw;
    // display: flex;
  }
  &__header {
    &--main {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      color: $color-secondary;
      transform: translateX(-50%);
      position: relative;
      left: 50vw;
    }

    &--sub {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      color: $color-primary;
      transform: translateX(-50%);
      position: relative;
      left: 50vw;
    }
  }
  &__filter {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    select {
      margin-left: 10px;
    }
  }

  .item {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .container-select-plan {
    width: 10vw;
    display: flex;
    padding-left: 10px;
    .css-2b097c-container {
      width: 100%;
    }
  }
  .container-select {
    width: 30vw;
    display: flex;

    .css-2b097c-container {
      width: 100%;
    }
  }

  .react-datepicker__input-container {
    input {
      height: 35px;
      margin-left: 4px;
    }
  }

  .css-14jk2my-container {
    width: 100%;
  }

  &__body {
    margin-top: 25px;
  }
}
