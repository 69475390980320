.treinamento {
  overflow-y: auto;
  margin-top: 5vh;
  &-title {
    color: $color-secondary;
    text-align: center;
  }

  .row-agendamento {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 30px;

    p {
      color: $color-secondary;
      font-size: 20px;
      font-weight: bold;
      margin-left: 20px;
    }

    button {
      border: 1px solid;
      border-color: #ccc;
      border-radius: 7%;
      font-size: 15px;
      font-weight: bold;
      background-image: linear-gradient(to right bottom, $color-primary, $color-secondary);
      color: $color-white;
      padding: 3px 10px 10px 5px;
      position: relative;
      margin-left: 20px;
      cursor: pointer;

      i {
        position: relative;
        top: 5px;
        margin-right: 7px;
        font-size: 20px;
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    
    &-item {
      margin-top: 10px;
      margin-bottom: 20px;
      padding-left: 5px;
      width: 100%;
      padding-bottom: 5px;
      
      &-title {
        font-size: 20px;
        color: $color-secondary;
        padding-bottom: 5px;
        display: flex;
        justify-items: center;
        justify-content: center;
        align-content: center;
        align-items: center;
        text-align: center;
        width: 550px;
        margin: auto;
      }
      &-description {
        width: 100%;
        font-size: 15px;
        padding-bottom: 5px;
        font-weight: bold;
        display: flex;
        justify-items: center;
        justify-content: center;
        align-content: center;
        align-items: center;
      }
      &-video {
        display: flex;
        justify-items: center;
        justify-content: center;
        align-content: center;
        align-items: center;
      }
    }

  }
}

.modal {

  img {
    width: 720px;
    height: 1280px;
  }
}