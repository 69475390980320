.over {
    width: 100vw;
    height: 92vh;
    display: flex;
    align-items: center;
    &__container {
        background: $color-white;
        width: 100%;
        height: 90%;
        display: flex;
        align-content: center;
        align-items: center;
        overflow-y: auto;
        overflow-x: hidden;
        // box-shadow: 0 5px 80px $color-black-opacity;
        flex-direction: column;
        border-radius: 10px;
        &-header {
            width: 100%;
            text-align: center;
        }

        &-body {
        }
    } 
    &__vigente {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        color: $color-secondary;
    }
    &__slider {
        width: 80vw;
        padding-top: 100px;
    }
}