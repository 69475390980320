.aluguelOnline {
    height: 100vh;
    background: #fff;
    
    &--header {
        display: flex;
        width: 100vw;
        color: $color-secondary;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding-top: 20px;
    }

    &--detail {
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 20px;
        .row {
            display: flex;
            justify-content: center;
            .value {
                font-weight: 700;
            }
            
            div {
                margin-right: 10px;
            }
        }
    }

    &--footer {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
}

.error {
    img {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}

.modalSucessAluguel {
    display: flex;
    justify-content: center;
    
    .sucess--aluguel {
        .title {
            color: $color-secondary;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            padding-top: 20px;
        }
    }

    .body {
        i {
            color: $color-secondary;
            padding-top: 20px;
            font-size: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
        }
    }

    .footer {
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
    }
}