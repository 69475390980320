.nova-imobiliaria-revendedor {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: lighten($color-secondary, 10);
  
  .error,
  .error:focus {
      border-color: salmon;
      & ~ div {
          background-color: salmon;
          color: white;
      }
  }

  .container-loader {
      width: 20%;
      display: flex;
      align-content: flex-end;
      justify-content: flex-end;
      align-items: flex-end;
      position: relative;
      
      .loader {
          left: 10px;
          bottom: -10px;
          position: absolute;
      }
  }
  
  .nova-imobiliaria-container {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      height:550px;
      width: 90%;
      padding: 10px;
      border-radius: 10px;
      position: relative;
  }
  .nova-imobiliaria-card {
      width: 100%;
      height: 100%;
      display: flex;
      border-radius: 10px;
  }
  .nova-imobiliaria-card--img {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      flex: 1;
      background: url(https://images.unsplash.com/photo-1516156008625-3a9d6067fab5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60) center center;
      background-size: cover;
  }
  .nova-imobiliaria-card--step {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      flex: 3;
      background: $color-white;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: center;
      justify-items: center;
      padding: 10px;

      &::-webkit-scrollbar {
        width: 8px;
    }
     
    &::-webkit-scrollbar-track {
        border-radius: 70px;
    }
     
    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 70px;
    }
      .nova-imobiliaria-card-welcome {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: center;
          align-content: center;
          align-items: center;

          h3 {
              margin-top: 5px;
              font-size: 25px;
              text-align: center;
          }

          small {
              text-align: center;
          }
      }
  
      .dados-empresa,
      .dados-usuario {
          display: flex;
          width: 100%;
          align-content: center;
          align-items: center;
          margin-top: 10px;
          flex-direction: column;
          height: 300px;
          .header {
              border-bottom: none;
              height: 10px !important;
              font-size: 15px;
              margin-top: 42px;
          }
          
          .body {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              width: 100%;
              .line {
                  display: flex;
                  width: 100%;
                  .item {
                      flex: 1;
                      margin-left: 5px;
                      .cnpj-creci {
                          flex-direction: row;
                          flex: .8;
                          .notify {
                              position: relative;

                              .notify-message {
                                  position: absolute;
                                  background-color: #009fff;
                                  color: #fff;
                                  width: 150px;
                                  transform: translate(-113px, -25px);
                                  @media (max-width: 768px){
                                      width: 400px;
                                      transform: translate(-364px, 5px);
                                      font-size: 11px;
                                  }
                                  border-radius: 5px;
                                  padding: 4px 8px;
                              }
                          }
                          span.btn {
                              background-image: linear-gradient(to right bottom, #00e3cf, #009fff);
                              border: none;
                              border-radius: 5px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              height: 30px;
                              width: 30px;
                              margin-top: auto;
                              color: #ffffff;
                          }
                      }
                    }
              }
              .progress-container {
                  padding-top: 20px;
                  right: 10px;
                  bottom: -80px;
                  display: flex;
                  justify-content: flex-end;
                  align-content: flex-end;
                  align-items: flex-end;
                  flex-direction: column;
                  width: 100%;
              }
          }
          
          .loading {
              width: 40px;
              float: right;
          }
      }overflow: auto;
        
      .dados-usuario {
          height: 100% !important;
          
          .header-employee {
              font-size: 16px;
              margin-top: 24px;
          }
          
          .employee {
              border-radius: 8px;
              width: 100%;
              background-color: rgb(250, 250, 250);
              box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
              margin-bottom: 36px;
              padding: 0 32px 32px 32px;
          .subheader-employee {
              font-size: 14px;
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              .close {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 30px;
                  height: 30px;
                  border-radius: 20px;
                  transition: .5s;
                  &:hover {
                      background: rgba($color: #000000, $alpha: .3);
                  }
              }
          }
      }
          .company-users-container {
              width: 100%;
          }
      }
      .step-container {
          margin: 0 0 0 0 !important;
      }
      .second-step-buttons {
          display: flex;
          :first-child {
              margin-right: 16px;
          }
      }

  }
  .userTerms {
      font-weight: 700;
      cursor: pointer;
      color: $color-secondary;
  }
  .container-password {
      width: 100%;
      display: flex;
      align-items: flex-end;
  }
  
  .container-password i {
      position: relative;
      top: -5px;
      font-size: 20px;
      cursor: pointer;
  }

}

.modalUserTerms {
  width: 80vw;
  .modal-header {
      display: flex;
      width: 100%;
      justify-content: center;
      align-content: center;
      align-items: center;
      padding: 10px 0;
  }
  .contract {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      
      iframe {
          border: none;
          width: 90%;
          height: 80vh;
      }
  }
  
}

.line-50 {
  width: 50% !important;
  align-self: baseline;
}

.planos {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;

  .container-planos {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
  }
  
  .plano-card {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
      border: 5px solid $color-secondary;
      margin-right: 20px;
      height: 300px;
      cursor: pointer;
  }

  .plano-icone {
      img {
          height: 50px;
          border-radius: 50px;
      }

      i {
          font-size: 50px;
          color: $color-white;
          background-image: linear-gradient(to right bottom, $color-primary, $color-secondary);
          border-radius: 50px;
          padding: 5px;
      }
  }
  .plano-header {
      color: $color-secondary;
      margin-top: 10px;
  }

  .plano-body {
      width: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 18px;

      b {
          text-decoration: none;
      }
  }
}


@media only screen and (max-width: 768px) { 
  .nova-imobiliaria-container {
      height:100% !important;
      width: 100% !important;
      padding: 10px;
      border-radius: 10px;
  }
  .nova-imobiliaria-card--img {
      display: none;
  }

  .line {
      flex-direction: column;
  }

  .step-container {
      height: 80vh;
      overflow: auto;
      
  }

  .dados-empresa {
      height: 79vh !important;
  }
}

.novo-revendedorCompanyLogin {
  background: $color-secondary;
  border-radius: 10px;
  height: 510px;
  width: 100%;
    
  .cardCompanyLogin {
    position: relative;
    width: 43%;
    height: 60%;
    left: 29%;
    top: 19%;
    border-radius: 10px;
    background: $color-white;
    overflow: none;
    
    .step-containerCompanyLogin {
      width: 100%;
      
      .loginCompanyLogin {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 90%;
        h1 {
          position: relative;
          display: flex;
          flex: 1;
          flex-direction: column;
          font-size: 25px;
          margin-top: 50px;
          margin-left: 30px;
          left: 1%;
          color: $color-secondary;
        }
        .login-itemCompanyLogin {
          position: relative;
          padding-top: 5px;
          width: 80%;
          left: 17%;
        }
        .login-buttonCompanyLogin {
          padding-top: 10px;
          position: relative;
          display: flex;
          flex-direction: column;
          width: 90px;          
          left: 45%;
        }
      }
    }
  }
}

.dados-usuario {
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
  margin-top: 10px;
  flex-direction: column;
  height: 300px;
  .header {
      border-bottom: none;
      height: 2vh;
      font-size: 15px;
  }
  
  .body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      .line {
          display: flex;
          width: 100%;
          .item {
              flex: 1;
              margin-left: 5px;
          }
      }
      .progress-container {
          padding-top: 20px;
          right: 10px;
          bottom: -80px;
          display: flex;
          flex: 1;
          flex-direction: column;
          width: 10%;
      }
  }
  
  .loading {
      width: 40px;
      float: right;
  }
}

.container-password {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.container-password i {
  position: relative;
  top: -5px;
  font-size: 20px;
  cursor: pointer;
}


@media only screen and (max-width: 768px) { 
    .nova-imobiliaria-container {
        height:100% !important;
        width: 100% !important;
        padding: 10px;
        border-radius: 10px;
    }
    .nova-imobiliaria-card--img {
        display: none;
    }

    .line {
        flex-direction: column;
    }

    .step-container {
        height: 80vh;
        overflow: auto;
        
    }

    .dados-empresa {
        height: 79vh !important;
    }
}