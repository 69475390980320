.renovacao {
    display: flex;
    height: 85vh;
    margin-top: 5vh;
    flex-direction: column;

    &__title {
        display: flex;
        color: $color-secondary;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: 100%;
        height: 8vh;
    }

    &__fee {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: flex-end;
        justify-items: flex-end;
        height: 8vh;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 20px;

        &--title {
            width: 40%;
            text-align: center;
        }

        &--input {
            width: 60%;
        }

        &--button {
            width: 20%;
            text-align: center;
        }
    }

    &__body {
        padding-top: 20px;
        padding-left: 3px;
    }

    .react-tabs__tab {
        width: 24.695%;
        border-radius: 5px 5px 0 0;
        border-color: $color-secondary;
        text-align: center;
        font-size: 20px;
        margin-left: 5px;
    
        &:nth-child(1) {
            margin-left: 0;
        }

        i {
            background: #ff7675;
            border-radius: 500px;
            padding: 3px;
            color: #fff;
            text-align: center;
            font-size: 10px;
            position: relative;
            top: -5px;
            text-align: center;
            // font-style: normal;
        }
    }

    .react-tabs__tab-panel--selected {
        border-top-color: #fff;
        position: relative;
        top: -10px;
    }
    
    .react-tabs__tab--selected,
    .react-tabs__tab-list {
        border-color: $color-secondary;
    }

    .react-tabs__tab--selected {
        background: $color-secondary;
        color: #fff;
    }

    .responsive_table {
        min-height: 0 !important;
        max-width: 100vw !important;
    }
    
    .export-container {
        width: 99vw !important;
    }
}

.modalRenovacao {
    width: 50vw;

    h1 {
        color: $color-secondary;
    }

    &--body {
        h1 {
            font-size: 15px;
        }

        div {
            display: flex;
            justify-content: center;
            align-content: center;
        
            span {
                margin-left: 10px;
                
                &:nth-child(1) {
                    font-weight: 700;
                }
            }
        }
    }

    &--footer {
        h1 {
            font-size: 15px;
            margin-top: 10px;
        }
        
        &--button,
        &--value {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            justify-items: center;
        }
    }
}

.modalRenovacao--newCard {
    width: 50vw;

    &--title {
        color: $color-secondary;
    }

    .card--data {
        display: flex;
        flex-direction: column;
        
        .line {
            display: flex;
        }
    }
    &--footer {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
}