.usuario_form {
    width: 100vw;
    height: 92vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    &__container {
        background: $color-white;
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 10px;
        &-header {
            margin-top: 10px;
            width: 100%;
            height: 10%;
            text-align: center;
        }
        &-body {
            padding: 32px;
        }
    }   
    &__button-container{
        margin-right: 32px;
        display: flex;
        justify-content: flex-end;
    }

    .payment_method {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-content: space-between;
        margin-top: 8%;
        
        &--container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
        }
        
        &-option {
            margin-right: 10px;
                
                input {
                    margin: 0 5px;
                }
        }
    }

    .level_credibility {
        display: flex;
        flex-direction: column;
        position: relative;
        input {
            display: none;
            
            & ~ div {
                display: none;
            }
        }

        input:checked {
            & ~ div {
                display: flex;
                flex-direction: column;
                font-size: 28px;
                align-content: center;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 100%;
                left: 13%;
            }
        }
        
        .happy {
            color: #2ed573;
        }

        .neutral {
            color: #ffa502;
        }

        .sad {
            color: #ff4757;
        }
    }

    .credibility--current {
        border: 1px solid $color-secondary;
        display: flex;
        font-size: 28px;
        align-content: space-between;
        justify-content: space-between;
        width: 50%;
        cursor: pointer;
        margin-top: 2%;

        &-divider {
            border-left: 1px solid $color-secondary;
            background-color: $color-secondary;
            color: $color-white;
            font-size: 15px;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            min-width: 40px;
        }
    }
    
    .btn-voltar {
        font-size: 20px;
        color: $color-secondary;
        position: relative;
        left: 20px;
        top: 10px;
    }
}