
.newModalCancelTransaction {
  max-width: 90vw;
  border-radius: 4px;

  .container-desc-venda {
    p {
      font-size: 14px;
    }

    label {
      font-size: 12px;
    }
  }

  &__body {
      width: 85vw;
  }

  &__footer {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;

      .btn-cancelar {
          width: 180px;
          height: 30px;
          font-size: 20px;
          background: $color-secondary;
          border-radius: 5px;
          color: $color-white;
          display: flex;
          justify-items: center;
          justify-content: center;
          align-content: center;
          margin-top: 10px;
          cursor: pointer;
      }
  }
  textarea {
      width: 100%;
      resize: none;
      outline-color: $color-secondary;
      border: 1px solid $color-grey-light3;
      height: 60px;
      margin: 10px;
      position: relative;
      left: -10px;
      &:focus {
          border-color: $color-secondary;
          outline: none !important;
      }
  }
}

.modalMes-icon {
  align-items: center;
  padding-bottom: 10px;
}

.modalMes-icon img {
  width: 70px;
}
.modalMes {
  width: 50vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-size: 25px;
  text-align: center;
  padding-left: 5%;
}
.modalMes-icon,
.modalMesFooter,
.modalMes-title {
  max-width: 44vw;
  display: flex;
  flex-direction: column;
}