#form-modal-bank_slip-credit-card {
  max-width: 290px !important;
  margin: 0 auto !important;
  margin-top: 16px !important;
}

.dialog {
  .dialog-body {
    padding: 16px;
  }
  .MuiPaper-rounded {
    border-radius: 6px !important;
  }
}

.dialog-content {
  padding: 8px;
  border-radius: 26px;

  .card-form {
    display: flex;
    align-items: center;

    .inputs {
      margin-left: 16px;
    }
    @media (max-width: 630px) {
      flex-direction: column;
      .inputs {
        margin-left: 0;
      }
    }
  }

  .venda-online-input {
    margin-bottom: 8px;
  }
}

#online_purchase {
  // background-color: $color-secondary;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .MuiCircularProgress-root {
    position: fixed;
  }

  .dialog {
    padding: 8px;
  }

  .column {
    max-width: 100%;
    padding: 8px;
    width: 326px;

    &.second {
      padding: 16px;
    }
  }

  .header-comp {
    display: flex;
    font-size: 32px;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 22px;
      margin: 28px;
      font-weight: bold;
    }
  }

  .item {
    .total_value {
      &.text {
        font-size: 20px;
      }

      &.value {
        font-size: 18px;
      }
    }
  }

  .logo-ucred {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 150px;
    @media (max-width: 630px) {
      display: none;
    }
  }

  #payment_method {
    .container {
      background-color: #fff;
      border-radius: 26px;
      width: 300px;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);

      .method {
        display: flex;
        height: 62px;
        align-items: center;
        width: 100%;
        img {
          width: 40px;
          margin-left: 16px;
        }

        p {
          margin-left: 16px;
        }

        i {
          margin: 16px;
          margin-left: auto;
          color: #32a852;
          font-size: 16px;
          font-weight: bolder;
        }
      }

      .next {
        border-radius: 0 0 26px 26px;
        height: 62px;
        width: 100%;
        font-size: 14px;
      }
    }
  }

  #credit_card {
    .container-card {
      background-color: #fff;
      border-radius: 26px;
      box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.7);
      display: flex;
      flex-direction: column;
      width: 95%;
      margin: 0 !important;
      @media (max-width: 630px) {
        width: 98%;
        height: 80vh;
      }

      @media (max-height: 580px) {
        height: 73vh;
      }

      .form {
        width: calc(100% - 16px);
        margin: 0 8px;
      }

      .rccs {
        margin-bottom: 16px;
      }

      .item {
        margin-bottom: 8px;
      }

      .buttons {
        display: flex;
        justify-content: center;
        padding: 18px;
        @media (max-width: 630px) {
          width: 326px;
        }
      }

      .cards {
        display: flex;
        flex: 1;
        overflow-y: auto;

        @media (max-width: 630px) {
          flex-direction: column;
        }
      }

      .venda-online-input {
        margin-bottom: 8px;
      }

      .next {
        border-radius: 0 0 26px 26px;
        height: 62px;
        width: 100%;
        font-size: 14px;
      }

      .next-step {
        display: flex;
        .back {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          border-radius: 0 0 0 26px;
          height: 62px;
          width: 100%;
          font-size: 14px;
        }

        .next {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          border-radius: 0 0 26px 0;
          height: 62px;
          width: 100%;
          font-size: 14px;
        }
      }
    }
  }

  #bank-slip {
    .container {
      background-color: #fff;
      border-radius: 26px;
      box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.7);
      display: flex;
      flex-direction: column;

      @media (max-width: 630px) {
        width: 330px;
        max-width: 300px;
        height: 80vh;
      }

      .bank-slip {
        height: 100%;
        display: flex;

        @media (max-width: 630px) {
          flex-direction: column;
        }

        overflow: auto;
      }

      .item {
        margin-bottom: 8px;
        &.buttons {
          margin-bottom: 32px;
        }
      }

      .line {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;

        button {
          width: 280px;
        }

        img {
          width: 24px;
          margin-right: 8px;
        }

        p {
          font-size: 12px;
        }
      }

      .next {
        border-radius: 0 0 26px 26px;
        height: 62px;
        width: 100%;
        font-size: 14px;
      }

      .next-step {
        display: flex;
        .back {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          border-radius: 0 0 0 26px;
          height: 62px;
          width: 100%;
          font-size: 14px;
        }

        .next {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          border-radius: 0 0 26px 0;
          height: 62px;
          width: 100%;
          font-size: 14px;
        }
      }
    }
  }

  #hybrid {
    .container {
      background-color: #fff;
      border-radius: 26px;
      box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.7);
      display: flex;
      flex-direction: column;
      max-height: 90vh;
      @media (max-width: 630px) {
        width: 330px;
        height: 80vh;
      }

      .form {
        width: 100%;
        max-width: 290px;
        margin: 0 8px;
        .payment_value_credit_card {
          margin: 8px 0;
        }
        .payment_value_bank_slip {
          margin-bottom: 16px;
        }
      }

      .rccs {
        margin: 26px auto;
      }

      .bank-slip-image {
        margin: 26px auto;
        border-radius: 16px;
        width: 290px;
        height: 182.86px;
        @media (max-width: 1024px) {
          display: none;
        }
      }

      .bank-slip-container {
        width: 100%;
        max-width: 326px;
      }

      .item {
        margin-bottom: 8px;
      }

      .buttons {
        display: flex;
        justify-content: center;
        padding: 18px;
      }

      .cards {
        display: flex;
        flex: 1;
        overflow-y: auto;

        @media (max-width: 630px) {
          flex-direction: column;
        }
      }

      .venda-online-input {
        margin-bottom: 8px;
      }

      .next {
        border-radius: 0 0 26px 26px;
        height: 62px;
        width: 100%;
        font-size: 14px;
      }

      .next-step {
        display: flex;
        .back {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          border-radius: 0 0 0 26px;
          height: 62px;
          width: 100%;
          font-size: 14px;
        }

        .next {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          border-radius: 0 0 26px 0;
          height: 62px;
          width: 100%;
          font-size: 14px;
        }
      }
    }
  }

  #online-purchase-error {
    .container {
      background-color: #fff;
      border-radius: 26px;
      box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.7);
      display: flex;
      flex-direction: column;

      @media (max-width: 630px) {
        width: 330px;
        max-width: 300px;
        height: auto;
      }

      .column {
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 630px) {
          &.house-img {
            display: none;
          }
        }
      }

      .images {
        width: fit-content;
        display: flex;
        position: relative;
        margin: 0 auto;
        .image-error {
          width: 50px !important;
          position: absolute;
          right: 0;
          bottom: 0;
          transform: translate(70%, 20%);
        }

        .image-house {
          width: 150px !important;
        }
      }

      .online-purchase-error {
        height: 100%;
        display: flex;

        @media (max-width: 630px) {
          flex-direction: column;
        }

        overflow: auto;
      }

      .item {
        margin-bottom: 8px;
        &.buttons {
          margin-bottom: 32px;
        }
      }

      .line {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;

        button {
          width: 280px;
        }

        img {
          width: 24px;
          margin-right: 8px;
        }

        p {
          font-size: 12px;
        }
      }

      .next {
        border-radius: 0 0 26px 26px;
        height: 62px;
        width: 100%;
        font-size: 14px;
      }

      .next-step {
        display: flex;
        .back {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          border-radius: 0 0 0 26px;
          height: 62px;
          width: 100%;
          font-size: 14px;
        }

        .next {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          border-radius: 0 0 26px 0;
          height: 62px;
          width: 100%;
          font-size: 14px;
        }
      }
    }
  }
}
