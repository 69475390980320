.viewRevendedor {
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  padding-top: 1px;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  &__line {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-content: space-between;
  }

  &__card {
      display: flex;
      flex-direction: column;
      height: 23vh;
      background: $color-secondary;
      width: 80%;
      border: 2px solid #fff;
      align-content: center;
      justify-content: center;
      align-items: center;
      color: #fff;
      cursor: pointer;
      
      &-header {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-content: center;
          i {
              font-size: 40px;
          }
      }
      &-body {
          font-size: 45px;
      }
  }
  .line-title {
      justify-content:center;
      align-items: center;
      align-content: center;
      font-size: 20px;
      color: $color-secondary;
      position: relative;
      padding-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .home {
      &__line {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-content: space-between;
          flex-direction: column;
      }
      
      &__card {
          display: flex;
          flex-direction: column;
          height: 35vh;
          background: $color-secondary;
          width: 100%;
          border: 2px solid #fff;
          align-content: center;
          justify-content: center;
          align-items: center;
          color: #fff;
          cursor: pointer;
          
          &-header {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              align-content: center;
              i {
                  font-size: 30px;
              }
          }
          &-body {
              font-size: 35px;
          }
      }
  }
}

.reload-icon {
    cursor: pointer;
    font-size: 33px;
    position: relative;
    left: 2%;
    padding-top: 5px;
}