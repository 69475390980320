.nota {
    width: 100vw;
    height: 92vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    &__container {
        background: $color-white;
        width: 100%;
        height: 90%;
        display: flex;
        overflow-y: auto;
        overflow-x: hidden;
        justify-content: flex-start;
        // box-shadow: 0 5px 80px $color-black-opacity;
        flex-direction: column;
        border-radius: 10px;
        &-header {
            margin-top: 10px;
            width: 100%;
            height: 10%;
            text-align: center;
        }
    }   
}