#status-document-signers.pageContainer {
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
  margin-top: 5vh;

  .responsive_table {
    padding: 0;
    margin: 0;
  }

  .table__filter-container {
    display: none;
  }

  .novoLocador {
    max-height: 88vh;
    padding: 48px;
    margin-bottom: 180px;
    overflow: auto;
    margin: auto;
    max-width: 1500px;

    .btn-voltar {
      color: $color-secondary;
      font-size: 20px;
      cursor: pointer;
    }

    .download-contracts {
      color: $color-secondary;
      font-size: 18pt;
    }

    .btn-new-document-send {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.modalDocumentDownload {
  border-radius: 8px;
  min-width: 700px;
  padding: 32px;

  .btn.btn--primary.disabled {
    background-color: #ddd;
    background-image: none;
    color: rgb(90, 90, 90);
  }

  h1 {
    font-size: 24px;
  }

  p {
    font-size: 12px;
    margin-top: 16px;
    text-align: center;
  }

  i {
    font-size: 28pt;
  }

  .btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    height: 150px;
    width: 150px;
  }

  .btn-group {
    display: flex;
    justify-content: space-around;
  }
}
