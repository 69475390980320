*,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #fdfdff;
  font-family: 'Roboto', sans-serif;
  // font-size: 62.5%;
  letter-spacing: 0.5px;
  line-height: 1.4;
  color: $color-grey-light;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(68, 68, 68, 0.512);
}

#mtable-container {
  ::-webkit-scrollbar {
    height: 15px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

#invoice-modal {
  .MuiDialog-paperWidthSm {
    max-width: 900px;
  }
}

.MuiTableCell-footer {
  border-bottom: none !important;
}

.MuiTableCell-root {
  border-bottom: 1px solid rgba(224, 224, 224, 0.4) !important;
}

.MuiTableCell-root {
  z-index: 0 !important;
}

.MuiToolbar-gutters {
  padding-left: 16px !important;
}

#mtable-container {
  background: #fff;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

#no-shadow {
  box-shadow: none;
}

.MuiTableCell-head {
  white-space: nowrap;
}

.MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.1) !important;
  -webkit-backdrop-filter: saturate(0%) blur(10px);
  backdrop-filter: saturate(180%) blur(10px);
}

/** DROPZONE */
.MuiDropzoneArea-text {
  color: rgba(113, 128, 150, 1) !important;
  font-size: 14pt !important;
}

.MuiDropzoneArea-icon {
  color: rgba(113, 128, 150, 1) !important;
  margin-bottom: 24px;
}

.MuiDropzoneArea-root {
  outline: none;
  border-color: rgba(113, 128, 150, 0.54) !important;
  border-width: 1px !important;
  border-radius: 8px !important;
  padding: 0 56px;
}

.MuiDropzonePreviewList-removeButton {
  top: 0 !important;
  right: 48px !important;
  color: rgb(207, 6, 6) !important;
  background-color: #eee !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
}

#notistack-snackbar {
  font-size: 16px;
}

.MuiTooltip-tooltip {
  font-size: 12px !important;
}
