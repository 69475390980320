.input {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    margin-top: 20px;
    margin-right: 10px;

    .label-icon {
        background-color: #666;
        margin-left: 8px;
        color: #fff;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 16px;
        height: 16px;
        &:hover > .label-icon-message {
            display: block !important;
            opacity: 0.9;
            pointer-events: all;
            transform: translateY(-50px);
        }
        .label-icon-message {
            pointer-events: none;
            position: absolute;
            background: rgb(95, 95, 95);
            opacity: 0;
            font-size: 12px;
            width: 320px;
            padding: 8px;
            border-radius: 8px;
            transition: 1s;
        }
    }

    .input__label {
        display: flex;
    }
    &__field {
        align-self: center;
        width: 98%;
        height: 30px;
        outline-color: $color-secondary;
        border: 1px solid $color-grey-light3;
        padding-left: 5px;
        transition: all .4s ease;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        // flex: 10;

        &-container {
            display: flex;
            flex-direction: row-reverse;
            width: 100%;
        }

        & ~ div {
            font-family: 'Roboto Mono', monospace;
            background-color: $color-grey-light3;
            color: $color-grey-light;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            flex: 1;
            display: flex;
            align-content:  center;
            align-items: center;
            justify-content: center;
            transition: all .4s ease;
            font-size: 14px;
            min-width: 60px;
        }
        
        &:focus {
            border-color: $color-secondary;
            outline: none !important;
            
            & ~ div {
                color: $color-white;
                background-color: $color-secondary;
            }
        }
        
        & + label {
            padding-left: 10px;
            letter-spacing: 2px;
            word-spacing: 3px;
            font-size: 10px;
            margin-bottom: 5px;
        }
    }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $color-secondary;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}