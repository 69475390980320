.container-values {
  width: 90vw;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 10px;
  border: 1px solid #ccc;

  #card_value {
    margin-top: 0;
  }
}