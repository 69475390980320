.dashboard {
    width: 100vw;
    height: 92vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    &__container {
        background: $color-white;
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: flex-start;
        overflow-y: auto;
        overflow-x: hidden;
        // box-shadow: 0 5px 80px $color-black-opacity;
        flex-direction: column;
        border-radius: 10px;
        &-header {
            margin-top: 10px;
            width: 100%;
            height: 10%;
            text-align: center;
        }

        &-body{
            padding: 10px;
        }
    }
    
    .antecipacao-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: $color-white;
        border-radius: 10px;
        box-shadow: 0 10px 20px $color-black-opacity;
        padding: 20px;
        cursor: pointer;
        
        &--first {
            background: $color-secondary;
        }

        &--second {
            background: darken($color-secondary, 10%);
        }

        &--ter {
            background: darken($color-secondary, 20%);
        }

        &-head {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            align-items: center;
            
            span:last-child {
                font-weight: 700;
            }
        }

        &-body {
            display: flex;
            position: relative;
            font-size: 30px;
            align-content: center;
            justify-content: center;
            align-items: center;
            text-shadow: 0 10px 10px $color-black-opacity;
            padding-bottom: 10px;
        }

        &-footer {
            border-top: 1px solid $color-white;
            font-size: 10px;
            display: flex;
            align-content: flex-end;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }

    .mes-card {
            margin-top: 20px;
            width: 100%;
            display: flex;
            flex-direction: column;
            color: $color-white;
            border-radius: 10px;
            box-shadow: 0 10px 20px $color-black-opacity;
            padding: 20px;
            cursor: pointer;

        &--first {
            background: $color-primary;
        }

        &--second {
            background: darken($color-primary, 10%);
        }
        
        &-head {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            align-items: center;
        }

        &-body {
            display: flex;
            position: relative;
            font-size: 30px;
            align-content: center;
            justify-content: center;
            align-items: center;
            text-shadow: 0 10px 10px $color-black-opacity;
            padding-bottom: 10px;
        }

        &-footer {
            border-top: 1px solid $color-white;
            font-size: 10px;
            display: flex;
            align-content: flex-end;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }

    .month {
        margin-top: 20px;
        width: 100%;
        max-width: 88vw;
        &-header {
            display: flex;
            justify-content: center;
            align-items: center;
            justify-items: center;
            font-weight: 700;
        }

        &-body {
            flex: 1;
            width: 100%;
            height: 200px;
        }
    
    }
    .styles_modal__gNwvD {
        min-width: 43vw;
    }
}

.modal__antecipacao {
    display: flex;
    flex-direction: column;
    &-head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        justify-items: center;
        font-weight: 700;
    }

    &-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        justify-items: center;
        font-weight: 700;
        font-size: 30px;
    }

    &-footer {
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        
        .btn-cancelar {
            background: #ff6b6b;
            padding: 10px;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
        }

        .btn-confirmar {
            background: #1dd1a1;
            padding: 10px;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
        }
    
    }
}

.modalA {
    min-width: 40vw !important;
    border-radius: 10px;
}