.modalDocs {
    display: flex;
    flex-direction: column;

    &--body {
        max-height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;
        .item {
            max-width: 80vw;
            
            embed {
                width: 100%;
            }
        }
    }
}