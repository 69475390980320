.ajustes {
    height: 92vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    &__container {
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 10px;
        &-header {
            margin-top: 10px;
            width: 100%;
            height: 10%;
            text-align: center;
        }
        &-body {
            padding: 0 32px;
            align-content: center;
            align-items: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
        
            .doc {
                iframe {
                    padding: 10px;
                    border: none;
                    width: 50vw;
                    height: 60vh;
                    border: 2px solid $color-secondary;
                    margin-top: 10px;
                }
            }
        }
    }  

    .aba-list {
        display: flex;
        list-style: none;
        justify-content: center;
        align-content: space-between;
        align-items: center;
        
        li {
            margin: 0 !important;
            cursor: pointer;
            padding: 20px;
            font-size: 20px;
            
            &:hover,
            &.active {
                color: $color-secondary;
            }

        
        }
    
    }

}