
.responsive_table {
    max-width: 90vw;
    min-width: 90vw;
    overflow: auto;
    position: relative;
    padding: 10px;
    min-height: 60vh;
    max-height: 60vh;
    // display: flex;
}
.table {
    width: 1491px;
    border-collapse: collapse;
    font-size: 14px;
    margin: 30px 0 0 0;
    border: 3px solid $color-secondary;
    thead {
        color: $color-white;
        background: $color-secondary;
    }
    tr:nth-child(even) {
        background: $color-grey-light1;
    }
    tr {
        cursor: pointer;
    }
    td {
        text-align: center;
    }
    
    tr:not(:first-child) {
        border-top: 1px solid $color-grey-light3;
    }
    tr:not(:last-child) {
        border-bottom: 1px solid $color-grey-light3;
    }
    tbody tr:hover {
        background: $color-grey-light3;
    }

    &__filter-container {
        display: flex;
        flex-direction: row-reverse;
        position: relative;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        padding: 5px;
            & i {
                position: absolute;
                top: 8px;
                left: 16%;
                color: $color-grey-light3;
            }
    }
    
    &__not-result {
        width: 90%;
        text-align: center;
        border: none;
        padding: 20px 0;
    }
    &__line-filter {
        &:hover {
            background: $color-white !important;
        }
    }
    &__filter {
        width: 87%;
        padding-left: 25px;
    }

    .line-delete {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: center;
        align-content: center;
        align-items: center;
    }
    
    .btn-delete {
        background: #ff6b6b;
        color: $color-white;
        width: 100%;
        height: 30px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        position: relative;
    }
}

.pagination {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
    padding-right: 30px;
}
.pagination a {
    color: black;
    float: right;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
  }
  
  .pagination a.active {
    background-image: linear-gradient(to right bottom, $color-primary, $color-secondary);
    color: white;
  }
  
  .pagination a:hover:not(.active) {background-color: #ddd;}

  .export-container {
    display: flex;
    justify-content: flex-end;
    justify-content: flex-end;
    align-items: flex-end;
    
    .btn-export {
        display: flex;
        padding: 10px;
        background: #00b894;
        color: $color-white;
        margin-top: 10px;
        border-radius: 5px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        align-content: center;
        
        i {
            margin-left: 5px;
        }
    }
}