#feedback-dialog {
  .MuiRating-iconFilled {
    color: #0086d9;
  }

  .final-opinion {
    margin-top: 16px;
  }

  .icons {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  input {
    all: initial !important;
  }

  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: initial;
    left: initial;
  }
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    position: initial;
    padding-left: initial;
    cursor: initial;
    line-height: initial;
    display: initial;
    color: initial;
  }
  [type='radio']:checked + label:before,
  [type='radio']:not(:checked) + label:before {
    content: '';
    position: initial;
    left: initial;
    top: initial;
    width: initial;
    height: initial;
    border: initial;
    border-radius: initial;
    background: initial;
  }
  [type='radio']:checked + label:after,
  [type='radio']:not(:checked) + label:after {
    content: '';
    width: initial;
    height: initial;
    background: initial;
    position: initial;
    top: initial;
    left: initial;
    border-radius: initial;
    -webkit-transition: initial;
    transition: initial;
  }
  [type='radio']:not(:checked) + label:after {
    opacity: initial;
    -webkit-transform: initial;
    transform: initial;
  }
  [type='radio']:checked + label:after {
    opacity: initial;
    -webkit-transform: initial;
    transform: initial;
  }
}

.social {
  &.facebook {
    color: #3b5998;
  }

  &.instagram {
    color: #bc1888;
    path {
      background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    }
  }

  &.youtube {
    color: #ff0000;
  }

  &.linkedin {
    color: #2867b2;
  }
}

@media print {
  #header {
    display: none;
  }
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background: #fff;
  height: 80px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);

  .popover-menu {
    display: flex;
    padding: 8px;
  }

  .image {
    .logo {
      height: 50px;
    }
  }

  .icon {
    margin-right: 8px;
  }

  .user {
    display: flex;
    align-items: center;
    background-color: #fafafa;
    border-radius: 16px;
    width: 250px;
    padding: 8px;
    @media (max-width: 599px) {
      width: auto;
    }

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 12px;
    }
    .company {
      margin: 0 8px;
    }
  }
}
